import React from 'react';
import { PropTypes } from 'prop-types';

import { Tooltip, IconButton, Button } from '@mui/material';


import Icon from './Icon';

const getButtonIcon = (icon, color, fontsize) => {
  const buttonfontsize = (fontsize) || 'large'
  return <Icon icon={icon} size={'large'} fontSize={buttonfontsize} color={color || 'secondary'}  />
}

export const CustomButton = ({ disabled, onClick, icon, color, className, style, children, iconColor, id }) => {
  color = color || ((disabled) ? 'disabled' : 'primary')
  iconColor = iconColor ||  ((disabled) ? 'disabled' : 'secondary')

  return (
    <Button
      id={id}
      disabled={!!disabled}
      onClick={onClick}
      className={className}
      variant="outlined"
      color={color}
      style={style}
      startIcon={getButtonIcon(icon, iconColor)}>
      {children || null}
    </Button>
  )
}

CustomButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.string
}

export const CustomIconButton = ({ disabled, onClick, icon, className, size = 'small', description, color, style = {} }) => {
  // const btnDisabled = (disabled === undefined) ? false : disabled
  // const _color = (color) || ((btnDisabled) ? '#c4c4c4' : color)

  color = color || ((disabled) ? 'disabled' : 'secondary')
  // iconColor = iconColor ||  ((disabled) ? 'disabled' : 'secondary')

  return (
    <Tooltip title={description}>
      <div>
        <IconButton disableFocusRipple disableRipple color={color} style={{ backgroundColor: 'transparent', ...style }}
          size="small"
          onClick={onClick}
          disabled={!!disabled}
          className={className}>
          {getButtonIcon(icon, color, size)}
        </IconButton>
      </div>
    </Tooltip>
  )
}

CustomIconButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object
}



export const inputStyles = {
  input: {
    color: "tertiary.contrastText",
    '& .MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-input .MuiOutlinedInput-input': {
      backgroundColor: "tertiary.contrastText",
      color: "tertiary.contrastText",
    },
    '& .MuiOutlinedInput-notchedOutline ': {
      borderColor: 'tertiary.contrastText',
    },
    // TODO: change borderbottomcolor
    '& .MuiInput-underline::after': {
      borderBottomColor: 'tertiary.contrastText',
      borderColor: 'tertiary.contrastText',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'tertiary.contrastText',
    }
  },
  inputfocused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'tertiary.contrastText',
      color: "tertiary.main",
    },
    '& .MuiOutlinedInput-root ': {
      borderColor: 'tertiary.contrastText',
      color: "tertiary.main",
      backgroundColor: "tertiary.contrastText"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'tertiary.contrastText',
    },
    '&. MuiOutlinedInput-input.Mui-disabled': {

    }
  },
  inputField: {
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: '#fff'
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: '#fff'
    },
  }
}



export const componentStyles = {
  paper: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: 1,
    backgroundColor: 'tertiary.contrastText'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 1
  },
  submit: {
    margin: (3, 0, 2)
  },
  input: {
    color: "tertiary.contrastText",
    '& .MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-input .MuiOutlinedInput-input': {
      backgroundColor: "tertiary.contrastText",

    },
    '& .MuiOutlinedInput-notchedOutline ': {
      borderColor: 'tertiary.contrastText',
    },
  },
  inputfocused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'tertiary.contrastText',
      color: "tertiary.main",
    },
    '& .MuiOutlinedInput-root ': {
      borderColor: 'tertiary.contrastText',
      color: "tertiary.main",
      backgroundColor: "tertiary.contrastText"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'tertiary.contrastText',
    },
    '&. MuiOutlinedInput-input.Mui-disabled': {

    }
  },
}

