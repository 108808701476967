import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { Typography } from '@mui/material'

import Layout from '../layout/Layout'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import Settings from './pages/Settings'
import { getDefaultPage, getNavigation, getPages } from './navigation'
import Home from './pages/Home'

const PageWrapper = ({ page }) => {
  if (!page.permission) return null
  return (
    <Fragment>
      <br />
      {(!isMobileOnly)
        && <Typography align='center' fontSize='h5.fontSize' color='primary'>
          {page.name}
        </Typography>}
      {page.node}
    </Fragment>
  )
}

PageWrapper.propTypes = {
  page: PropTypes.object,
}

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
function PrivateRoute({ isAuthenticated, permitted = true, children, ...rest }) {
  const getContent = (location) => {
    if (!isAuthenticated) {
      return <Redirect
        to={{ pathname: '/login/', state: { from: location } }}
      />
    }
    if (!permitted) {
      return <Redirect
        to={{ pathname: '/', state: { from: location } }}
      />
    }
    return children
  }
  return (
    <Route
      {...rest}
      render={({ location }) => getContent(location)}
    />
  )
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  permitted: PropTypes.bool,
  children: PropTypes.node
}

function HomeRoute({ isAuthenticated, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{ pathname: isAuthenticated ? '/' : '/login/', state: { from: location } }}
        />
      )}
    />
  )
}

HomeRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node
}



export default function Router({ resetUser, session }) {

  const pages = getPages(session, true)
  const navigation = getNavigation(session, false)
  const defaultPage = getDefaultPage(navigation, session)


  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login/"><Layout session={session}><Login session={session} resetUser={resetUser} /></Layout></Route>
          <Route exact path="/reset_password/"><Layout session={session}><ForgotPassword session={session} /></Layout></Route>
          <PrivateRoute exact path="/settings/" isAuthenticated={session.isAuthenticated}><Layout pageName="Einstellungen" session={session} navigation={navigation}><Settings session={session} /></Layout></PrivateRoute>
          {defaultPage
            ? <PrivateRoute exact path="/" isAuthenticated={session.isAuthenticated}><Layout session={session} navigation={navigation}><Redirect
              to={{
                pathname: `/${defaultPage.url}/`,
              }}
            /></Layout></PrivateRoute>
            : <PrivateRoute exact path="/" isAuthenticated={session.isAuthenticated}><Layout navigation={navigation} session={session}><Home navigation={navigation} session={session} /></Layout></PrivateRoute>}
          {pages.map(page => <PrivateRoute key={`private-route-to-${page.url}`} exact path={`/${page.url}/`} isAuthenticated={session.isAuthenticated} permitted={page.permission}><Layout pageName={page.name} session={session} navigation={navigation}><PageWrapper page={page} /></Layout></PrivateRoute>)}
          <HomeRoute path="*" isAuthenticated={session.isAuthenticated}></HomeRoute>
        </Switch>
      </BrowserRouter>
    </div>
  )
};
