import { Handle, Position } from 'reactflow';
import { getNodeStyle } from './nodestyle';


export const containerStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};

export default function HouseNode({ data }) {

  return (
    <div
      style={getNodeStyle(data.sizePx)}
    >
      <div className="text-center">
        <img src={'/icons/house.svg'} alt="House Icon" height={'100px'} />
      </div>
      <Handle
        type="target"
        position={Position.Top}
        id="top"
        className="handletop"
      />
      <Handle
        type="target"
        position={Position.Right}
        id="right"
        className="handleright"
      />
      <Handle
        type="target"
        position={Position.Left}
        id="left"
        className="handleleft"
      />
      <Handle
        type="target"
        position={Position.Bottom}
        className="handlebottom"
        id="bottom"
      />
      <Handle
        type="source"
        position={Position.Top}
        id="top"
        className="handletop"
      />
      <Handle
        type="source"
        position={Position.Right}
        id="right"
        className="handleright"
      />
      <Handle
        type="source"
        position={Position.Left}
        id="left"
        className="handleleft"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="handlebottom"
        id="bottom"
      />
    </div>
  );
}