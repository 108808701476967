import { Handle, Position } from 'reactflow';
import { Typography } from '@mui/material'
import { getNodeStyle } from './nodestyle'


export default function BatteryNode({ data }) {

  const getIconPath = (soc) => {
    if (soc == null) return '/icons/battery_0.svg'
    if (soc < 20) return '/icons/battery_0.svg'
    if (soc < 40) return '/icons/battery_1.svg'
    if (soc < 60) return '/icons/battery_2.svg'
    if (soc < 80) return '/icons/battery_3.svg'
    return '/icons/battery_4.svg'
  }


  return (
    <div
      style={getNodeStyle(data.sizePX)}
    >
      <div className="text-center">
        <img src={getIconPath(data.soc)} alt="Battery Icon" height={'50px'} />
        <Typography variant="subtitle2" color='primary'>Batterie</Typography>
        <Typography variant="caption" color='primary'>Ladezustand: {data.soc}%</Typography>
      </div>
      <Handle
        type="source"
        id="right"
        position={Position.Right}
        className="handleright"
      />
      <Handle
        type="target"
        id="right"
        position={Position.Right}
        className="handleright"
      />
    </div>
  );
}