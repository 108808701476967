


import React from 'react';
import { PropTypes } from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, Typography } from '@mui/material';


export default function InfoTooltip({ children }) {

  return (
    <Tooltip title={<Typography sx={{ fontSize: 14 }}>{children}</Typography>} enterTouchDelay={0}>
      <InfoOutlinedIcon />
    </Tooltip>
  )

}

InfoTooltip.propTypes = {
  message: PropTypes.string,
}