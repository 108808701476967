import React, { useMemo, Fragment } from 'react';
import { PropTypes } from 'prop-types';

import Flow from './Flow';
import { getNodes, nodeTypes } from './nodes/nodes';
import { edgeTypes, getEdges } from './edges/edges';

import './flow.css';

export default function EnergyFlow({ flowheight, flowData }) {

  const flow = flowData.flow
  const consumptionSplitter = (flow.heatpump != null) || (flow.wallbox != null)

  const _edgeTypes = useMemo(() => (edgeTypes), [])
  const _nodeTypes = useMemo(() => (nodeTypes), [])

  const edges = getEdges(flowData, consumptionSplitter)
  const nodes = useMemo(() => getNodes(flowData, consumptionSplitter), [])


  return (
    <Fragment>
      <Flow flowheight={flowheight} nodes={nodes} edges={edges} edgeTypes={_edgeTypes} nodeTypes={_nodeTypes} />
    </Fragment>
  )
}

EnergyFlow.propTypes = {
  session: PropTypes.object
}
