import React from 'react'

import { Typography, Backdrop, CircularProgress } from '@mui/material'

export default function LoadingPage () {
  return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={true}
  >
    <CircularProgress color="inherit" />
    &nbsp;&nbsp;
    <Typography fontSize='h4.fontSize' color='primary'>Wird geladen...</Typography>

  </Backdrop>
}
