import React from 'react';
import PropTypes from 'prop-types';


import { Box, Stack, Typography } from '@mui/material';
import { CustomIconButton } from '../StyledElements';

export default function TablePagination({ page, setPage, nPages }) {

  return nPages !== null && nPages > 1 && <Box display="flex" justifyContent="center">
    <Stack direction="row" spacing={2}>
      <CustomIconButton
        disabled={page === 0}
        icon='previous'
        onClick={() => setPage(page - 1)}
      />
      <Typography>{page + 1}</Typography>
      <CustomIconButton
        disabled={page === nPages - 1}
        icon='next'
        onClick={() => setPage(page + 1)}
      />
    </Stack>
  </Box>
}

TablePagination.propTypes = {
  page: PropTypes.number,
  nPages: PropTypes.number,
  setPage: PropTypes.func
};