

import React from 'react';

import { TextField as MuiTextField } from '@mui/material';
import { inputStyles } from '../StyledElements';



export default function NumberInput({ value, disabled = false, onChange, inputRef, error = false, name, autoComplete, label = null, placeholder = '', ...props }) {

  // const _props = (autoComplete === null) ? {} : { autoComplete: autoComplete || "off" }
  return (
    <MuiTextField
      value={value}
      size="small"
      type="number"
      disabled={disabled}
      label={label}
      name={name}
      sx={inputStyles.inputField}
      // inputProps={{sx: inputStyles.newInput}}
      onChange={(e) => onChange(e.target.value)}
      error={error}
      // focused
      placeholder={placeholder}
      inputRef={inputRef}
      variant={'standard'}
      autoComplete="off"
      // {..._props}
      {...props}
    />
  )
}



