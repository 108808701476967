import React, { Fragment, useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'

import { Grid, Typography, Stack, Box } from '@mui/material'

import LoadingPage from '../../react_utils/LoadingPage'
import InfoTooltip from '../../react_utils/InfoTooltip'
import { API_URL_cWALLBOXCHARGINGHISTORY } from '../../urls'
import ChargingHistoryTable from './wallbox/ChargingHistoryTable'
import ExcelExport from '../../react_utils/ExcelExport'
import { date2FormattedString, date2MonthString } from '../../react_utils/utils'
import DatePicker from '../../react_utils/DatePicker'

export default function ChargingHistory({ session }) {
  const [chargingHistory, setChargingHistory] = useState(null)

  const [date, setDate] = useState(new Date())


  useEffect(() => {
    session.backendClient.get(API_URL_cWALLBOXCHARGINGHISTORY).then((res) => {
      setChargingHistory(res.data)
    })

  }, [])

  const getExcelData = async () => {
    return session.backendClient.get(API_URL_cWALLBOXCHARGINGHISTORY, { params: { month: date2MonthString(date) } }).then((res) => {
      const history = res.data
      if (history.length > 0) {
        return Object.values(Object.groupBy(history, ({ wallbox }) => wallbox)).map((wbHistory) => {
          const wallboxName = wbHistory[0].wallbox_name
          return createExcelSheet(wallboxName, wbHistory)
        })
      } else { return [createExcelSheet('Ladehistorie', history)] }
    })
  }

  const createExcelSheet = (sheetName, chargingHistoryToDownload) => {
    const excelRows = [['Datum', 'Lademenge [kWh]', 'Ladedauer [Std]', 'Ladestart', 'Ladeende']]
    chargingHistoryToDownload.forEach((info) => {
      excelRows.push([
        date2FormattedString(info.start_time.slice(0, 10)),
        info.energy ? info.energy.toFixed(2) : '0',
        info.duration,
        info.start_time.slice(11, 16),
        info.end_time ? info.end_time.slice(11, 16) : ''
      ])
    })
    return { name: sheetName, data: excelRows }
  }

  if (chargingHistory === null) return <LoadingPage />


  return (
    <Fragment>
      <div className="text-center">
        <Typography sx={{ marginY: '10px' }}>
          Ladevorgänge <InfoTooltip>
            In der Ladehistorie können Sie die Ladevorgänge Ihres E-Autos einsehen.
            Bitte beachten Sie, dass es sich bei der Lademenge lediglich um eine Hochrechnung handelt, falls Sie keinen separaten Zähler für die Wallbox haben, der an Energieimpuls angeschlossen ist.
          </InfoTooltip>
        </Typography>
      </div>
      <Grid container justify="center" sx={{ marginBottom: '50px' }}>
        {/* <br />
          <Stack direction={direction} spacing={cardSpace}>
            <Card sx={{ minWidth: cardWidth, padding: "10px", backgroundColor: "tertiary.main" }} >

              <Typography color='secondary' variant="h6">
                Ladung gesamt
              </Typography>
              <br />
              <Typography color='primary' variant="h4">
                {chargingHistory.total_energy_kwh.toFixed(2)} kWh
              </Typography>

            </Card>
            <Card sx={{ minWidth: cardWidth, padding: "10px", backgroundColor: "tertiary.main" }} >

              <Typography color='secondary' variant="h6">
                Ladung Monat
              </Typography>
              <br />
              <Typography color='primary' variant="h4">
                {chargingHistory.month_energy_kwh.toFixed(2)} kWh
              </Typography>

            </Card>
            <Card sx={{ minWidth: cardWidth, padding: "10px", backgroundColor: "tertiary.main" }} >
              <Typography color='secondary' variant="h6">
                PV-Anteil gesamt
              </Typography>
              <br />
              <Typography color='primary' variant="h4">
                {chargingHistory.total_pv_energy_kwh.toFixed(2)} kWh
              </Typography>

            </Card>
            <Card sx={{ minWidth: cardWidth, padding: "10px", backgroundColor: "tertiary.main" }} >
              <Typography color='secondary' variant="h6">
                PV-Anteil Monat
              </Typography>
              <br />
              <Typography color='primary' variant="h4">
                {chargingHistory.month_pv_energy_kwh.toFixed(2)} kWh
              </Typography>

            </Card>
            <br />
          </Stack>
          <br />
          <br /> */}
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          <ChargingHistoryTable session={session} chargingHistory={chargingHistory} />
        </Grid>

      </Grid>
      <Typography sx={{ marginBottom: '20px' }}>Ladehistorie als Exceldatei exportieren:</Typography>
      <Stack direction="row" spacing={2}>
        <DatePicker date={date} setDate={setDate} views={["month", "year"]} />
        <ExcelExport
          exporter={getExcelData}
          fileName={`Ladehistorie-${date ? date2MonthString(date) : ''}`}
          disabled={!date}
        />
      </Stack>
    </Fragment>
  )
}

ChargingHistory.propTypes = {
  session: PropTypes.object
}
