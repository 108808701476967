import React, { Fragment, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';

import { filterObj, string2Date } from '../../../react_utils/utils';
import LoadingPage from '../../../react_utils/LoadingPage';
import CustomPlot from '../../../react_utils/plots/Plot';
import DatePicker from '../../../react_utils/DatePicker';
import { LoadHistoryTitle, ProductionHistoryTitle, getColorByKey } from './flowhistoryutils';

export default function DailyFlow({ getFlow, session }) {
  const [date, setDate] = useState(new Date())
  const [flowHistory, setFlowHistory] = useState(null)

  useEffect(() => {
    getFlow(date).then(res => setFlowHistory(res))
  }, [date])


  if (flowHistory === null) return <LoadingPage />

  const time = flowHistory.time.map(t => string2Date(t))
  const powerFlow = filterObj({ 'export_to_grid': "Einspeisung", 'import_from_grid': "Netzbezug", 'battery_discharge': "Batterieentladung", 'production': "Erzeugung", 'load': "Last" }, key => key in flowHistory)
  const productionFlow = filterObj({ 'prod_to_load': "Last", 'prod_to_bat': "Batterie", 'prod_to_grid': "Einspeisung" }, key => key in flowHistory)
  const consumptionFlow = filterObj({ 'load_from_prod': "Erzeugung", 'load_from_bat': "Batterie", 'load_from_grid': "Netzbezug" }, key => key in flowHistory)
  const batterySoCFlow = filterObj({ 'battery_soc': "SoC" }, key => key in flowHistory)

  return (
    <Fragment>
      <Grid container justify="center">
        <DatePicker date={date} setDate={setDate} views={["month", "year"]} sx={{ marginBottom: '20px' }} />
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          <Stack direction="column" spacing={5}>
            <CustomPlot xlabel='Zeit' ylabel={"Energie [kWh]"} type='bar'
              title={"Leistungsflüsse"}
              datasets={
                Object.entries(powerFlow).map(([key, label]) => ({
                  label: label,
                  data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                  borderColor: getColorByKey(key),
                  fill: false,
                  backgroundColor: getColorByKey(key, "0.8"),
                  pointRadius: 0,
                }))}
              xScaleOptions={{
                type: 'time',
                time: {
                  displayFormats: { hour: 'HH' },
                  tooltipFormat: 'dd.MM.yyyy',
                  minUnit: 'day'
                }
              }}
              stacked={false}
            />
            {(Object.keys(consumptionFlow).length > 0) ?
              <CustomPlot xlabel='Zeit' ylabel={"Energie [kWh]"} type='bar'
                title={<ProductionHistoryTitle timeHorizon="daily" />}
                datasets={
                  Object.entries(productionFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "1"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                    tooltipFormat: 'dd.MM.yyyy',
                    minUnit: 'day'
                  }
                }}
                stacked={true}
              /> : null}
            {(Object.keys(consumptionFlow).length > 0)
              ? <CustomPlot xlabel='Zeit' ylabel={"Energie [kWh]"} type='bar'
                title={<LoadHistoryTitle timeHorizon="daily" />}
                datasets={
                  Object.entries(consumptionFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "1"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                    tooltipFormat: 'dd.MM.yyyy',
                    minUnit: 'day'
                  }
                }}
                stacked={true}
              />
              : null}
          </Stack>
        </Grid>
        {/* PIE CHARTS */}
        {/* <Grid container justify="center">
          <Grid xs={12} sm={6} md={6} item justifyContent="center" alignItems="center">
            {(Object.keys(consumptionFlow).length > 0)
              ? <CustomPlot xlabel='Zeit' ylabel={"Leistung [kW]"} type='line'
                title={"Deckung des Verbrauchs"}
                datasets={
                  Object.entries(consumptionFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "1"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                  tooltipFormat: 'dd.MM.yyyy'
                  }
                }}
                stacked={true}
                height={plotheight * 3}
              />
              : null}
          </Grid>
          <Grid xs={12} sm={6} md={6} item justifyContent="center" alignItems="center">

          </Grid>
        </Grid> */}
      </Grid>
    </Fragment >

  );
}

DailyFlow.propTypes = {
  session: PropTypes.object
}
