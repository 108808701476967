import React from "react";
import { PropTypes } from 'prop-types';
import { getColor } from "../../../react_utils/plots/plotUtils";
import InfoTooltip from "../../../react_utils/InfoTooltip";


export const getColorByKey = (key, opacity = null) => {
  switch (key) {
    case 'export_to_grid':
    case 'prod_to_grid':
      return getColor('orange', opacity)
    case 'import_from_grid':
    case 'load_from_grid':
      return getColor('red', opacity)
    case 'battery_charge':
    case 'prod_to_bat':
    // return getColor('lightgreen', opacity)
    case 'battery_discharge':
    case 'load_from_bat':
    case 'battery_soc':
      return getColor('green', opacity)
    // return getColor('yellow')
    case 'production':
    case 'load_from_prod':
      return getColor('cyan', opacity)
    case 'load':
    case 'prod_to_load':
      return getColor('blue', opacity)
    default:
      return getColor('gray', opacity)
  }
}

export function LoadHistoryTitle({ timeHorizon }) {
  return <>
    Deckung des Verbrauchs <InfoTooltip>
      In diesem Graphen ist Ihr Energieverbrauch über {(timeHorizon === 'daily') ? "den Tag" : (timeHorizon === 'daily') ? "den Monat" : "das Jahr"} dargestellt.
      Dabei ist farblich markiert, welcher Anteil Ihres Verbrauchs jeweils durch Erzeugung, Netzbezug oder Energie aus Ihrer Batterie (falls vorhanden) gedeckt wurde.
    </InfoTooltip>
  </>
}

LoadHistoryTitle.propTypes = {
  timeHorizon: PropTypes.oneOf(['hourly', 'daily', 'monthly']),
}


export function ProductionHistoryTitle({ timeHorizon }) {
  return <>
    Nutzung der Erzeugung <InfoTooltip>
      In diesem Graphen ist Ihre PV-Erzeugung über {(timeHorizon === 'daily') ? "den Tag" : (timeHorizon === 'daily') ? "den Monat" : "das Jahr"} dargestellt.
      Dabei sind die Anteile an der PV-Erzeugung farblich markiert, welche direkt verbraucht, in das Netz eingespeist oder zur Aufladung Ihrer Batterie (falls vorhanden) genutzt wurden.
    </InfoTooltip>
  </>
}

ProductionHistoryTitle.propTypes = {
  timeHorizon: PropTypes.oneOf(['hourly', 'daily', 'monthly']),
}
