import { Handle, Position } from 'reactflow';
import { Typography } from '@mui/material'
import { getNodeStyle } from './nodestyle';


export default function HeatpumpNode({ data }) {

  return (
    <div
    style={getNodeStyle(data.sizePx)}
    >
      <div className="text-center">
      <img src={'/icons/heatpump.svg'} alt="Heatpump Icon" height={'50px'} />
        <Typography variant="subtitle2" color='primary'>Wärmepumpe</Typography>
      </div>
      <Handle
        type="source"
        id="left"
        className="handleleft"
        position={Position.Left}
      />
      <Handle
        type="target"
        id="left"
        className="handleleft"
        position={Position.Left}
      />
    </div>
  );
}