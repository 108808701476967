import React, { Fragment, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { PropTypes } from 'prop-types';

import { Typography, Grid, Card, Stack, Button, TextField, Alert, Snackbar } from '@mui/material';
import { inputStyles } from '../../react_utils/StyledElements';

import { API_URL_PASSWORDCHANGE } from '../../urls';
import CharsInput from '../../react_utils/fields/CharsInput';
import CustomSnackbar from '../../react_utils/CustomSnackbar';

const styles = {
  ...inputStyles
}


const cardWidth = 350;
// const cardHeight = (isMobileOnly) ? 150 : 200;
const cardSpace = (isMobileOnly) ? 2 : 4;
const direction = (isMobileOnly) ? "column" : "row";

export default function Settings({ session }) {

  const [newPassword1, setNewPassword1] = useState(null)
  const [newPassword2, setNewPassword2] = useState(null)

  const [snackbar, setSnackbar] = useState({open: false, msg: '', severity: 'success'})


  const showMsg = (severity, msg) => {
    setSnackbar({open: true, msg: msg, severity: severity})
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (newPassword1 !== newPassword2) {
      setNewPassword1(null)
      setNewPassword2(null)
      showMsg("error", "Die Passwörter stimmen nicht überein!")
    } else {
      const passwordFormData = new FormData()
      passwordFormData.append('new_password', newPassword1)
      // passwordFormData.append('new_password2', newPassword2)
      // Axios update_password API call
      session.backendClient.post(API_URL_PASSWORDCHANGE, passwordFormData).then(res => {
        showMsg("success", "Passwort erfolgreich geändert!")
        setNewPassword1(null)
        setNewPassword2(null)
      }).catch(
        error => {
          showMsg("error", error.response.data.join(' '))
          setNewPassword1(null)
          setNewPassword2(null)
        })
    }
  }

  // const handleFormFieldChange = (event) => {
  //   switch (event.target.id) {
  //     case 'new_password1': setNewPassword1(event.target.value); break
  //     case 'new_password2': setNewPassword2(event.target.value); break
  //     default: return null
  //   }
  // }


  return (
    <Fragment>
      <br />
      <div className="text-center">
        {(!isMobileOnly)
          ? <Typography fontSize='h5.fontSize' color='primary'>
            Einstellungen
          </Typography>
          : null}
      </div>
      <Grid container justify="center">
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          <br />
          <Stack direction={direction} spacing={cardSpace}>
            <Card sx={{ minWidth: cardWidth, padding: "10px", backgroundColor: "tertiary.main" }} >
              <Typography color='secondary' variant="h6">
                Passwort ändern
              </Typography>
              <br />
              <form sx={styles.form} noValidate onSubmit={handleSubmit}>
                <Stack direction={'column'} spacing={3}>
                  <CharsInput
                    label='Passwort eingeben'
                    placeholder="Passwort"
                    type="password"
                    onChange={v => setNewPassword1(v)}
                  />
                  <CharsInput
                    label='Passwort erneut eingeben'
                    placeholder="Passwort"
                    type="password"
                    onChange={v => setNewPassword2(v)}
                  />
                  {/* <div className="d-grid"> */}
                  <Button
                    type="submit"
                    variant="outlined"
                    style={{ color: '#efefef', borderColor: '#efefef' }}
                    sx={styles.submit}
                  >
                    Passwort ändern
                  </Button>
                </Stack>
                {/* </div> */}
              </form>
            </Card>
            <br />
          </Stack>
          <br />
          <br />
          <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          </Grid>
        </Grid>
      </Grid>
      <CustomSnackbar message={snackbar.msg} duration={3000} severity={snackbar.severity} open={snackbar.open} setIsOpen={isOpen => setSnackbar(s => ({...s, open: isOpen}))} />
    </Fragment >
  )
}

Settings.propTypes = {
  session: PropTypes.object
}
