import React, { } from 'react';

import { PropTypes } from 'prop-types';
import Customers from './users/Customers';
import TabView from '../../react_utils/TabView';
import Employees from './users/Employees';


// const cardWidth = 350;
// // const cardHeight = (isMobileOnly) ? 150 : 200;
// const cardSpace = (isMobileOnly) ? 2 : 4;
// const direction = (isMobileOnly) ? "column" : "row";

export default function Users({ session }) {
  if (!session.user.is_superuser) return <Customers session={session} />
  return <TabView
    components={[
      {
        title: 'Kunden',
        getChild: () => <Customers
          session={session}
        />
      },
      {
        title: 'Mitarbeiter',
        getChild: () => <Employees
          session={session}
        />
      }
    ]}
  />
}

Users.propTypes = {
  session: PropTypes.object
}