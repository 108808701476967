import { checkIfValueIsEmpty, isValidEmailAddress } from "../../../react_utils/utils"




export const getEmployeeFormTemplate = () => [
  {
    type: 'input_chars',
    key: 'username',
    name: 'Benutzer',
    width: '50%',
    disabled: true
  },
  {
    type: 'input_chars',
    key: 'first_name',
    name: 'Vorname',
    width: '50%',
  },
  {
    type: 'input_chars',
    key: 'last_name',
    name: 'Nachname',
    width: '50%',
  },
  {
    type: 'input_chars',
    key: 'email',
    name: 'E-Mail',
    width: '50%',
    isEmpty: (value) => checkIfValueIsEmpty(value) || !isValidEmailAddress(value)
  }
]
