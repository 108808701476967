import React, { useEffect, useRef, useState } from 'react'
import { PropTypes } from 'prop-types'

import { Typography } from '@mui/material'


import { API_URL_CONFIGURECLIENT, API_URL_CLIENT, API_URL_ROOF } from '../../../urls'
import { getClientConfigureFormTemplate } from './ClientConfigureForm'
import CustomModal from '../../../react_utils/modals/CustomModal'
import SaveModalFooter from '../../../react_utils/modals/SaveModalFooter'
import ConfirmationModal from '../../../react_utils/modals/ConfirmationModal'
import { CustomButton } from '../../../react_utils/StyledElements'
import CustomForm, { getEmptyFieldsErrorFromTemplate } from '../../../react_utils/form/CustomForm'
import LoadingPage from '../../../react_utils/LoadingPage'
import DropDown from '../../../react_utils/fields/DropDown'
import { getRandomId, round } from '../../../react_utils/utils'
import Checkbox from '../../../react_utils/fields/Checkbox'
import CustomSnackbar from '../../../react_utils/CustomSnackbar'
import Table from '../../../react_utils/table/Table'


export const emptyClientForm = {
  serial: null,
  location: null,
  roof_set: []
}

export default function ClientConfigureFormModal({ isOpen, setIsOpen, client, resetParent, session }) {
  const [clientForm, setClientForm] = useState({ ...emptyClientForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [loaded, setLoaded] = useState(false)

  // messaging
  const [snackbar, setSnackbar] = useState({ open: false, msg: "", severity: "success" })

  const interval = useRef(null)

  const hasPv = clientForm?.impulsinverter_set && clientForm.impulsinverter_set.length > 0

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }


  useEffect(() => {
    if (isOpen && !interval.current) { interval.current = setInterval(() => updateData(), 5000) }
    if (!isOpen && interval.current) { clearInterval(interval.current); interval.current = null }
  }, [isOpen])


  const clearData = () => {
    setClientForm({ ...emptyClientForm, ...(client || {}) })
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
  }

  const wallboxDeviceList = clientForm?.impulswallbox_set ? clientForm.impulswallbox_set.map(w => ({ label: w.name, value: w.id, emeter: w.emeter })) : []
  const heatpumpDeviceList = clientForm?.impulsheatpump_set ? clientForm.impulsheatpump_set.map(hp => ({ label: hp.name, value: hp.id, emeter: hp.emeter })) : []
  const deviceList = [...wallboxDeviceList, ...heatpumpDeviceList]

  const loadData = () => {
    setClientForm({ ...emptyClientForm, ...(client || {}) })
    Promise.all([
      session.backendClient.get(API_URL_CLIENT + client.id, { params: { nested: true } })
        .then(res => { setClientForm(d => ({ ...d, ...res.data })); return res }),
      session.backendClient.get(API_URL_ROOF, { params: { location: client.location } })
        .then(res => { setClientForm(d => ({ ...d, roof_set: res.data })); return res })
    ]).then(() => setLoaded(true))
  }

  const updateData = () => {
    session.backendClient.get(API_URL_CLIENT + client.id, { params: { nested: true } })
      .then(res => {
        const newClientForm = res.data
        setClientForm(c => {
          var updated = false
          const keys = ['impulsemeter_set', 'impulswallbox_set', 'impulsinverter_set', 'impulsrelay_set']
          keys.forEach((key) => {
            const prevN = c[key].length
            const newIds = newClientForm[key].map(x => x.id)
            c[key] = c[key].filter(x => newIds.includes(x.id))
            updated = (updated || (c[key].length !== prevN))

            const ids = c[key].map(x => x.id)
            const additional = newClientForm[key].filter(x => !ids.includes(x.id))
            c[key].push(...additional)
            updated = (updated || additional.length > 0)
          })
          const prevNHeatpumps = c.impulsheatpump_set.length
          c.impulsheatpump_set = c.impulsheatpump_set.filter(h => !!c.impulsrelay_set.find(r => h.relay == r.id))
          updated = (updated || c.impulsheatpump_set.length !== prevNHeatpumps)
          if (newClientForm.configuration_locked !== c.configuration_locked) {
            c.configuration_locked = newClientForm.configuration_locked
            updated = true
          }
          return updated ? { ...c } : c
        })
      })
  }


  const submit = async (form) => {
    var promise = session.backendClient.post(
      API_URL_CONFIGURECLIENT,
      {
        impulsinverter_set: form.impulsinverter_set,
        impulswallbox_set: form.impulswallbox_set,
        impulsheatpump_set: form.impulsheatpump_set,
        impulsemeter_set: form.impulsemeter_set,
        impulsrelay_set: form.impulsrelay_set,
      },
      { params: { client_id: client.id } })
    if (hasPv) {
      promise = promise.then((res) => {
        return session.backendClient.put(API_URL_ROOF, form.roof_set, { params: { location: form.location } }).then(() => res)
      })
    }
    return promise
  }

  const onSubmit = async (onSuccess) => {
    if (clientForm.configuration_locked) {
      setLoadingElements({ ...loadingElements, submitError: 'Gerät ist nicht erreichbar, bitte in 1 Minute erneut versuchen!', inProgress: false, showMissingFields: true })
      return
    }

    const emptyFieldsError = getEmptyFieldsErrorFromTemplate(template, clientForm, emptyClientForm)
    if (emptyFieldsError) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false, showMissingFields: true })
      console.error(emptyFieldsError)
      return
    }

    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })

    return submit(clientForm).then((res) => {
      const msg = client.configured ? "Änderungen gespeichert!" : "Das Gerät wurde erfolgreich konfiguriert!";
      setSnackbar(s => ({ ...s, open: true, severity: "success", msg: msg }))
      resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "client:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const onUnassign = async (onSuccess) => {

    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })

    return session.backendClient.put(API_URL_CLIENT + client.id, { location: null }).then((res) => {
      const msg = "Der Standort wurde erfolgreich entfernt."
      setSnackbar(s => ({ ...s, open: true, severity: "success", msg: msg }))
      resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "client:onUnassign"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }


  const getFooter = (toggle) => {
    if (!client) return null
    if (client.configured) {
      return <ConfirmationModal
        title={"Soll das Gerät wirklich zurückgesetzt werden?"}
        resetParent={resetParent}
        confirm={() => { return session.backendClient.put(API_URL_CLIENT + client.id, { configured: false, configuration_locked: true }).then(() => { setIsOpen(false); resetParent("Das Gerät wurde erfolgreich zurückgesetzt!", "success") }) }}
        getOpenButton={(toggle) => <CustomButton color="error" onClick={toggle} icon="bin" iconColor="error">Reset</CustomButton>}
        btnLabel="Reset bestätigen"
      />
    }
    return (
      <SaveModalFooter
        id="submit_client_form"
        // disabled={client.configured || client.configuration_locked} can not be updated on the fly
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        onDelete={client.configured ? null : () => onUnassign(toggle)}
        saveBtnLabel={client.configured ? "Speichern" : "Konfigurieren"}
        delteBtnLabel={client.configured ? null : "Zuordnung entfernen"}
      />
    )
  }

  const template = getClientConfigureFormTemplate(clientForm?.location, clientForm?._location, hasPv, clientForm.configured, session)

  const modalTitle = !!clientForm && (clientForm.configured) ? "Steuergerät" : "Steuergerät konfigurieren"

  // const stateCols = (client && client.configured) ? [{ name: 'Status', key: 'state' }] : []
  const stateCols = []
  return (
    <>
      <CustomModal size='lg' title={modalTitle} getFooter={getFooter} onToggle={onToggle} isOpen={isOpen} setIsOpen={setIsOpen}>
        {loaded ? null : <LoadingPage />}
        <CustomForm
          template={template}
          form={clientForm}
          setForm={setClientForm}
          defaultForm={emptyClientForm}
          showMissingFields={loadingElements.showMissingFields}
          session={session}
        />
        {clientForm && clientForm.impulsinverter_set && clientForm.impulsinverter_set.length > 0 ?
          <>
            <div className="text-center">
              <Typography fontSize='h6.fontSize' color='primary'>
                Wechselrichter
              </ Typography>
            </div>
            <Table
              columns={[
                { name: 'Gerät', key: 'name', style: { width: '20%' } },
                { name: 'Modbus-Config', key: 'modbus_address', style: { width: '20%' } },
                { name: 'Batterie', key: 'battery_capacity', style: { width: '20%' } },
                ...stateCols
              ]}
              rows={clientForm.impulsinverter_set.map(inverter => ({
                name: inverter.identifier,
                modbus_address: inverter.address.substring(7),
                battery_capacity: `${round(inverter.battery_capacity, 2)} kWh`,
                state: inverter.state_representation,
              }))}
            />
          </> : null}
        {clientForm && clientForm.impulswallbox_set && clientForm.impulswallbox_set.length > 0 ?
          <>
            <br />
            <div className="text-center">
              <Typography fontSize='h6.fontSize' color='primary'>
                Wallboxen
              </ Typography>
            </div>
            <Table
              columns={[
                { name: 'Gerät', key: 'name', style: { width: '20%' } },
                { name: 'Modbus-Config', key: 'modbus_address', style: { width: '20%' } },
                ...stateCols
              ]}
              rows={clientForm.impulswallbox_set.map(wallbox => ({
                name: wallbox.identifier,
                modbus_address: wallbox.address.substring(7),
                state: wallbox.state_representation,
              }))}
            />
          </> : null}
        {clientForm && clientForm.impulsrelay_set && clientForm.impulsrelay_set.length > 0 ?
          <>
            <br />
            <div className="text-center">
              <Typography fontSize='h6.fontSize' color='primary'>
                Relais
              </ Typography>
            </div>
            <Table
              columns={[
                { name: 'Gerät', key: 'name', style: { width: '20%' } },
                { name: 'Modbus-Config', key: 'modbus_address', style: { width: '20%' } },
                { name: 'Wärmepumpe', key: 'actions', style: { width: '20%' } },
                ...stateCols
              ]}
              rows={clientForm.impulsrelay_set.map(relay => {
                const heatpump = clientForm.impulsheatpump_set.find(h => h.relay === relay.id)
                return {
                  name: relay.identifier,
                  modbus_address: relay.address.substring(7),
                  state: relay.state_representation,
                  actions:
                    <Checkbox
                      checked={!!heatpump} disabled={clientForm.configured}
                      onChange={checked => {
                        setClientForm(f => {
                          f = { ...f }
                          if (checked) {
                            if (!heatpump) f.impulsheatpump_set.push({ id: -getRandomId(), impuls: relay.impuls, name: `Wärmepumpe ${relay.identifier}`, identifier: relay.identifier, location: relay.location, relay: relay.id, resourcetype: 'ImpulsHeatpump' })
                          }
                          else f.impulsheatpump_set = f.impulsheatpump_set.filter(h => h.relay !== relay.id)
                          return f
                        })
                      }}
                    />
                }
              })}
            />
          </> : null}
        {clientForm && clientForm.impulsemeter_set && clientForm.impulsemeter_set.length > 0 ?
          <>
            <br />
            <div className="text-center">
              <Typography fontSize='h6.fontSize' color='primary'>
                Zähler
              </ Typography>
            </div>
            <Table
              columns={[
                { name: 'Gerät', key: 'name', style: { width: '20%' } },
                { name: 'Modbus-Config', key: 'modbus_address', style: { width: '20%' } },
                { name: 'Zuweisung', key: 'actions', style: { width: '20%' } },
                ...stateCols
              ]}
              rows={clientForm.impulsemeter_set.map(emeter => ({
                name: emeter.identifier,
                modbus_address: emeter.address.substring(7),
                state: emeter.state_representation,
                actions:
                  <DropDown
                    onChange={(value) => {
                      setClientForm(f => {
                        f = { ...f }
                        const wallboxes = f.impulswallbox_set
                        wallboxes.forEach(w => { if (w.emeter === emeter.id) w.emeter = null })
                        const chosenWallbox = wallboxes.find(w => w.id === value)
                        if (chosenWallbox) chosenWallbox.emeter = emeter.id
                        const heatpumps = f.impulsheatpump_set
                        heatpumps.forEach(hp => { if (hp.emeter === emeter.id) hp.emeter = null })
                        const chosenHeatpump = heatpumps.find(hp => hp.id === value)
                        if (chosenHeatpump) chosenHeatpump.emeter = emeter.id
                        return f
                      })
                    }}
                    options={deviceList}
                    disabled={clientForm.configured}
                    value={deviceList.find(d => d.emeter === emeter.id)?.value || null}
                    label='Gerät wählen' />
              }))}
            />
          </> : null}
        {clientForm && clientForm.impulsheatpump_set && clientForm.impulsheatpump_set.length > 0 ?
          <>
            <br />
            <div className="text-center">
              <Typography fontSize='h6.fontSize' color='primary'>
                Wärmepumpen
              </ Typography>
            </div>
            <Table
              columns={[
                { name: 'Gerät', key: 'name', style: { width: '20%' } },
                { name: 'Modbus-Config', key: 'modbus_address', style: { width: '20%' } },
                ...stateCols
              ]}
              rows={clientForm.impulsheatpump_set.map(heatpump => ({
                name: heatpump.identifier,
                modbus_address: clientForm.impulsrelay_set.find(r => r.id === heatpump.relay).address.substring(7),
                state: heatpump.state_representation
              }))}
            />
          </> : null}
        <br />
        <br />
      </CustomModal>
      <CustomSnackbar message={snackbar.msg} duration={3000} severity={snackbar.severity} open={snackbar.open} setIsOpen={(isOpen) => setSnackbar(s => ({ ...s, open: isOpen }))} />
    </>
  )
}

ClientConfigureFormModal.propTypes = {
  client: PropTypes.object,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
