import { getEmptyFields, getRandomId } from "../../../react_utils/utils"
import { CustomButton, CustomIconButton } from '../../../react_utils/StyledElements'
import CustomForm, { getEmptyFieldsErrorFromTemplate } from "../../../react_utils/form/CustomForm"
import CharsInput from "../../../react_utils/fields/CharsInput"
import { Divider } from "@mui/material"


const cardinalDirections = [
  { label: 'Nord', value: 'N' },
  { label: 'Nord-Ost', value: 'NE' },
  { label: 'Ost', value: 'E' },
  { label: 'Süd-Ost', value: 'SE' },
  { label: 'Süd', value: 'S' },
  { label: 'Süd-West', value: 'SW' },
  { label: 'West', value: 'W' },
  { label: 'Nord-West', value: 'NW' },
]

const emptyRoofForm = { 'kwp': null, direction: null, is_flat: false }

const RoofTemplate = [
  {
    type: 'dropdown',
    name: 'Himmelsrichtung',
    key: 'direction',
    options: cardinalDirections,
    separator: false
  },
  {
    type: 'input_number',
    key: 'kwp',
    name: 'kWp',
    separator: false
  },
  {
    type: 'checkbox',
    key: 'is_flat',
    name: 'Flachdach',
    separator: false
  },

]


export const getClientConfigureFormTemplate = (locationId, location, hasPv, disabled, session) => [
  {
    type: 'input_chars',
    key: 'serial',
    name: 'Geräte ID',
    width: '50%',
    disabled: true
  },
  {
    type: 'custom',
    getFieldHtml: () => {
      return <CharsInput
        label={'Adresse'}
        value={location?.address || ''}
        disabled={true}
        name={'address'}
      />
    }
  },
  {
    type: 'custom',
    name: 'Dächer',
    key: 'roof_set',
    isEmpty: val => !val || val.length === 0 || val.some(roof => getEmptyFieldsErrorFromTemplate(RoofTemplate, roof, emptyRoofForm) != null),
    getFieldHtml: (form, setForm, showMissingFields, fieldIsEmpty) => {
      const deleteRoof = (roofId) => {
        setForm(f => ({ ...f, roof_set: f.roof_set.filter(i => i.id !== roofId) }))
      }

      const updateRoof = (roofId, update) => {
        setForm(f => {
          const roofs = [...f.roof_set]
          const roof = roofs.find(x => x.id === roofId)
          update = (typeof update === 'function') ? update(roof) : update
          Object.entries(update).forEach(([key, value]) => { roof[key] = value })
          return { ...f, roof_set: roofs }
        })
      }

      const addRoof = (direction = null, kwp = null, is_flat = false) => {
        setForm(f => (
          {
            ...f,
            roof_set: [...f.roof_set, {
              id: -getRandomId(), kwp, direction, is_flat, location: locationId
            }]
          }
        ))
      }

      return (
        <div style={{ marginLeft: '50px' }}>
          <Divider sx={{ bgcolor: "primary.main", marginTop: 2 }} />
          {form.roof_set
            .map(roof => <div key={`roof_${roof.id}`}>
              {disabled
                ? null
                : <CustomIconButton onClick={() => deleteRoof(roof.id)} style={{ color: "#efefef", float: 'right' }} icon="bin" />}
              <CustomForm
                template={RoofTemplate.map(r => ({ ...r, disabled: disabled }))}
                form={roof}
                setForm={(f) => { updateRoof(roof.id, f) }}
                defaultForm={emptyRoofForm}
                showMissingFields={showMissingFields}
                session={session}
              />
              <Divider sx={{ bgcolor: "primary.main", marginTop: 2 }} />
            </div>)
          }
          {disabled
            ? null
            : <CustomButton onClick={() => addRoof()} style={{ width: '200px' }} icon="add" id="add-roof-btn">Dach hinzufügen</CustomButton>}
        </div>
      )
    },
    condition: form => hasPv,
  }
]
