import React, { Fragment, useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { PropTypes } from 'prop-types';

import { Grid, Chip, IconButton, CircularProgress } from '@mui/material';

import { API_URL_CLIENT, API_URL_CLIENTSSHTUNNEL } from '../../../urls';
import LoadingPage from '../../../react_utils/LoadingPage';
import ClientLocationFormModal from './ClientLocationFormModal';
import ClientConfigureFormModal from './ClientConfigureFormModal';

import { useDidMountEffect, string2Datetime, datetime2FormattedString, addNMinutes } from '../../../react_utils/utils';
import Table from '../../../react_utils/table/Table';
import TablePagination from '../../../react_utils/table/TablePagination';
import CharsInput from '../../../react_utils/fields/CharsInput';
import Icon from '../../../react_utils/Icon';
import CustomSnackbar from '../../../react_utils/CustomSnackbar'
import ConfirmationModal from '../../../react_utils/modals/ConfirmationModal';


export default function Clients({ assigned, configured, session }) {

  const [clients, setClients] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [chosenClient, setChosenClient] = useState(null)

  // chosen device to delete date
  const [chosenDateClient, setChosenDateClient] = useState(null)

  // chosen device for ssh tunnel
  const [chosenSshClientIds, setChosenSshClientIds] = useState([])

  // messaging
  const [snackbar, setSnackbar] = useState({ open: false, msg: "", severity: "success" })

  // pagination
  const [batch, setBatch] = useState(0)
  const [nPages, setNPages] = useState(null)
  const batchSize = 20

  // search filter
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    resetState();
  }, [])

  useEffect(() => {
    if (!loaded) return // run if 'searchInput' changes, but not on initial render
    const delayDebounceFn = setTimeout(() => {
      if (batch !== 0) setBatch(0)
      else getClients()
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchInput])

  useDidMountEffect(() => { // run if 'batch' changes, but not on initial render
    resetState()
  }, [batch])


  const resetState = () => {
    setLoaded(false)
    getClients().then(() => { setLoaded(true) });
  }

  const getClients = async () => {
    const params = {
      batch_size: batchSize,
      batch,
      search: (searchInput) || null,
      assigned: assigned,
      configured: configured,
      ascending_ids: false,
    }
    return session.backendClient.get(API_URL_CLIENT, { params })
      .then(res => { setNPages(Number(res.headers.length)); setClients(res.data) })
  }

  const sshTunnel = async (clientId) => {
    setChosenSshClientIds(l => [...l, clientId])
    return session.backendClient.post(API_URL_CLIENTSSHTUNNEL, null, { params: { client_id: clientId } }).then((res) => {
      const { host, port, user } = res.data
      const connectionStr = `ssh -p ${port} ${user}@${host}`
      navigator.clipboard.writeText(connectionStr)
      setSnackbar(s => ({ ...s, open: true, msg: "Der SSH-Tunnel wurde geöffnet.", severity: 'success' }))
    })
      .catch(error => {
        console.error('Error in "client:sshTunnel"', error, error.stack)
        setSnackbar(s => ({ ...s, open: true, msg: "Der SSH-Tunnel konnte nicht geöffnet werden.", severity: 'error' }))
      })
      .then(() => {
        setChosenSshClientIds(l => l.filter(i => i !== clientId))
      })
  }

  return (
    <Fragment>
      {(!loaded) ? <LoadingPage /> : null}
      <Grid container justify="center">
        <Grid container justifyContent="flex-end">
          <CharsInput
            label="Suche"
            onChange={v => setSearchInput(v)}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          {clients &&
            <Table
              columns={[
                { name: 'Geräte ID', key: 'id' },
                { name: 'Standort', key: 'location' },
                { name: '', key: 'location_link' },
                { name: 'Zuletzt online', key: 'timestamp' },
                {
                  name: 'Online seit', key: 'online_since', headerprops: { style: { width: 180 } },
                  onActive: (row) => {
                    return row.client.first_query_timestamp ? <IconButton sx={{ p: 0 }} disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={() => setChosenDateClient(row.client)}>
                      <Icon icon={'bin'} color='error' fontSize='small' />
                    </IconButton> : null
                  },
                  getLink: () => null
                },
                { name: '', key: 'state' },
                ...session.user.is_superuser ? [{
                  name: '', key: 'ssh', headerprops: { style: { width: 60 } },
                  onActive: (row) => !chosenSshClientIds.includes(row.client.id) && <IconButton sx={{ p: 0 }} disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={() => sshTunnel(row.client.id)}>
                    <Icon icon={'ssh'} sx={{ color: 'secondary.main' }} fontSize='small' />
                  </IconButton>,
                  getLink: () => null
                }] : [],
              ]}
              rows={clients.map(client => ({
                id: client.serial,
                client: client,
                location_link: client.location && <Link to={`/standort/${client.location}`} style={{ textDecoration: 'none', width: '100%' }}>
                  <Icon icon={'location'} sx={{ color: 'secondary.main' }} fontSize='small' />
                </Link>,
                location: client._location?.name || client._location?.address || '',
                timestamp: client.latest_query_timestamp ? datetime2FormattedString(string2Datetime(client.latest_query_timestamp)) : '',
                online_since: client.first_query_timestamp ? datetime2FormattedString(string2Datetime(client.first_query_timestamp)) : '',
                link: (row) => { setChosenClient(client) },
                ssh: chosenSshClientIds.includes(client.id) && <CircularProgress sx={{ color: 'secondary.main' }} size="20px" />,
                state: (string2Datetime(client.latest_query_timestamp) > addNMinutes(new Date(), -5)) ? <Chip label="Online" variant='outlined' color='secondary' /> : <Chip label="Offline" variant='outlined' color='error' />
              }))}
              emptyTableText="Keine Steuergeräte"
            />
          }
        </Grid>
        <Grid xs={12} sm={12} md={12} item display="flex" justifyContent="center" paddingTop={"10px"}>
          <TablePagination
            nPages={nPages}
            page={batch}
            setPage={setBatch}
          />
        </Grid>
      </Grid>
      <ClientLocationFormModal
        session={session}
        resetParent={resetState}
        client={chosenClient}
        isOpen={!!chosenClient && !chosenClient.location}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenClient(null) }} />
      <ClientConfigureFormModal
        session={session}
        resetParent={resetState}
        client={chosenClient}
        isOpen={!!chosenClient && !!chosenClient.location}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenClient(null) }} />
      <ConfirmationModal
        title={"Soll das Datum wirklich zurückgesetzt werden?"}
        resetParent={resetState}
        isOpen={!!chosenDateClient}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenDateClient(null) }}
        confirm={() => { return session.backendClient.put(API_URL_CLIENT + chosenDateClient.id, { first_query_timestamp: null }).then(() => { setSnackbar(s => ({ ...s, open: true, msg: "Das Datum wurde gelöscht.", severity: 'success' })) }) }}
      />
      <CustomSnackbar message={snackbar.msg} duration={3000} severity={snackbar.severity} open={snackbar.open} setIsOpen={(isOpen) => setSnackbar(s => ({ ...s, open: isOpen }))} />
    </Fragment >
  )
}

Clients.propTypes = {
  session: PropTypes.object
}