

import React from 'react';

import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';


export default function Checkbox({ label, checked, disabled = false, onChange, error = false, checkBoxProps }) {

  return (
    <FormControlLabel
      // color='secondary'
      checked={checked}
      disabled={disabled}
      onChange={(e) => onChange(e.target.checked)}
      control={<MuiCheckbox checked={checked} disableFocusRipple disableRipple color={error ? 'error' : 'secondary'} sx={{ backgroundColor: 'transparent', color: error ? 'error.main' : 'secondary.main' }} {...(checkBoxProps || {})} />}
      label={label} />
  )
}



