import React, { Fragment } from 'react';

import { PropTypes } from 'prop-types';

import TabView from "../../react_utils/TabView";
import ClientGroup from "./clients/ClientGroup";


export default function Clients({ session }) {

  return (
    <Fragment>
      <TabView
        components={[
          {
            title: 'Alle',
            getChild: () => <ClientGroup
              assigned={null}
              configured={null}
              session={session}
            />
          },
          {
            title: 'Nicht zugeordnet',
            getChild: () => <ClientGroup
              assigned={false}
              configured={null}
              session={session}
            />
          },
          {
            title: 'Zugeordnet',
            getChild: () => <ClientGroup
              assigned={true}
              configured={false}
              session={session}
            />
          },
          {
            title: 'Konfiguriert',
            getChild: () => <ClientGroup
              assigned={true}
              configured={true}
              session={session}
            />
          }
        ]}
      />
    </Fragment>
  )
}

Clients.propTypes = {
  session: PropTypes.object
}
