import React from 'react'

import { PropTypes } from 'prop-types'
import { FormGroup } from 'reactstrap'
import { usePlacesWidget } from 'react-google-autocomplete'
import './GooglePredictionStyle.css'

import { Divider, Typography } from '@mui/material'

import { checkIfValueIsEmpty, date2String, defaultIfEmpty, string2Date } from '../utils'
import DropDown from '../fields/DropDown'
import MultiSelect from '../fields/MultiSelect'
import Toggle from '../fields/Toggle'
import Checkbox from '../fields/Checkbox'
import DateInput from '../fields/DateInput'
import CharsInput from '../fields/CharsInput'
import NumberInput from '../fields/NumberInput'

const fieldPropTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  setForm: PropTypes.func,
  defaultForm: PropTypes.object,
  template: PropTypes.arrayOf(PropTypes.object),
  showMissingFields: PropTypes.bool,
  wrap: PropTypes.bool
}


const onChange = (value, field, setForm, template, defaultForm) => {
  if (field.onChange) return field.onChange(field.key, value)
  setForm(f => {
    value = (typeof value === 'function') ? value(f[field.key], f) : value
    let newForm = { ...f, [field.key]: value }
    const additionalUpdate = (field.additionalUpdate) ? field.additionalUpdate(field.key, value, f) : {}
    newForm = { ...newForm, ...additionalUpdate }
    template.filter(field => field.key).filter(field => field.condition && !field.condition(newForm) && newForm[field.key] !== defaultForm[field.key])
      .forEach(field => { newForm[field.key] = defaultForm[field.key] })
    return newForm
  })
}



export const wrapField = (html, name, separator = true) => {
  return <FormGroup>
    {name ? <Typography color='primary'>{name}</Typography> : null}
    {html}
    {(separator !== false) ? <Divider sx={{ bgcolor: "primary.main", marginTop: 2 }} /> : null}
  </FormGroup >
}

wrapField.propTypes = fieldPropTypes


export const SubtitleField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {
  const html = <Typography fontSize={field.fontsize || 'h6.fontSize'} color='primary'>{field.text}</Typography>
  return wrap ? wrapField(html, null, field.separator) : html
}

SubtitleField.propTypes = fieldPropTypes


export const ToggleField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty
  const html = <Toggle
    disabled={field.disabled}
    onChange={(value) => onChange(value, field, setForm, template, defaultForm)}
    options={field.options || [{ value: true, label: 'Ja' }, { value: false, label: 'Nein' }]}
    error={(!field.optional && showMissingFields && isEmpty(form[field.key]))}
  />
  return wrap ? wrapField(html, field.name, field.separator) : html
}

ToggleField.propTypes = fieldPropTypes


export const NumberInputField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty
  const html = <NumberInput
    value={defaultIfEmpty(form[field.key])}
    disabled={field.disabled}
    name={field.key}
    onChange={value => onChange(value, field, setForm, template, defaultForm)}
    error={!field.optional && showMissingFields && isEmpty(form[field.key])}
  />
  return wrap ? wrapField(html, field.name, field.separator) : html
}

NumberInputField.propTypes = fieldPropTypes


export const CharsInputField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {

  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty
  const html =
    <CharsInput
      label={field.name}
      value={defaultIfEmpty(form[field.key])}
      disabled={field.disabled}
      name={field.key}
      onChange={value => onChange(value, field, setForm, template, defaultForm)}
      error={!field.optional && showMissingFields && isEmpty(form[field.key])}
    />
  return wrap ? wrapField(html, null, field.separator) : html
}

CharsInputField.propTypes = fieldPropTypes

export const GoogleAddressInputField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty
  const { ref: materialRef } = usePlacesWidget({
    apiKey: session.creds.google_api_key,
    onPlaceSelected: (place) => {
      const street_and_number = place.formatted_address.split(',')[0]
      const zip_and_city = place.formatted_address.split(',')[1].substring(1)
      if (field.selectAddress) {
        field.selectAddress({ street_and_number, zip_and_city })
      }
    },
    options: {
      componentRestrictions: { country: 'de' },
      types: ['address'],
      fields: ['name', 'formatted_address', 'address_components']
    }
  })
  const html =
    <CharsInput
      value={defaultIfEmpty(form[field.key])}
      disabled={field.disabled}
      label={field.name}
      name={"street_and_number"}
      onChange={value => onChange(value, field, setForm, template, defaultForm)}
      error={!field.optional && showMissingFields && isEmpty(form[field.key])}
      inputRef={materialRef}
      autoComplete={true}
    />
  return wrap ? wrapField(html, null, field.separator) : html
}

GoogleAddressInputField.propTypes = fieldPropTypes


export const CheckBoxField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty
  const html = <Checkbox
    label={field.name}
    disabled={field.disabled}
    onChange={checked => onChange(checked, field, setForm, template, defaultForm)}
    checked={!!form[field.key]}
    error={!field.optional && showMissingFields && isEmpty(form[field.key])}
  />
  return wrap ? wrapField(html, null, field.separator) : html
}

CheckBoxField.propTypes = fieldPropTypes


export const DropdownField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty
  const html = <DropDown
    onChange={value => onChange(value, field, setForm, template, defaultForm)}
    label={field.name}
    options={field.options}
    value={form[field.key]}
    disabled={field.disabled}
    error={(!field.optional && showMissingFields && isEmpty(form[field.key]))}
  />

  return wrap ? wrapField(html, null, field.separator) : html
}

DropdownField.propTypes = fieldPropTypes

export const MultiSelectField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty
  const html = <MultiSelect
    text={field.text || field.name}
    sort={true}
    options={field.options}
    values={form[field.key]}
    disabled={field.disabled}
    onChange={values => onChange(values, field, setForm, template, defaultForm)}
    error={(!field.optional && showMissingFields && isEmpty(form[field.key]))}
  />
  return wrap ? wrapField(html, field.name, field.separator) : html
}

MultiSelectField.propTypes = fieldPropTypes




export const DateField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty
  const html = <DateInput
    disabled={field.disabled}
    value={form[field.key] ? string2Date(form[field.key]) : null}
    error={(!field.optional && showMissingFields && isEmpty(form[field.key]))}
    onChange={(date) => onChange(date ? date2String(date) : null, field, setForm, template, defaultForm)}
  />
  return wrap ? wrapField(html, field.name, field.separator) : html
}

DateField.propTypes = fieldPropTypes


export const CustomField = ({ field, form, setForm, defaultForm, template, showMissingFields, session, wrap = true }) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty
  const html = <>
    {field.getFieldHtml(form, setForm, showMissingFields, field.key ? isEmpty(form[field.key], true) : false)}
  </>
  return wrap ? wrapField(html, field.name, field.separator) : html
}

CustomField.propTypes = fieldPropTypes
