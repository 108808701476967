import React from 'react';

import { PropTypes } from 'prop-types';

import { Typography, Card } from '@mui/material';

import Checkbox from '../../../react_utils/fields/Checkbox';
import InfoTooltip from '../../../react_utils/InfoTooltip';

const cardWidth = 300;
const cardHeight = 150;


export default function HeatpumpCard({ heatpumpSetPoint, setHeatpumpSgReady, infoText = true, session }) {
  return (
    <Card key={`heatpumpsetpoint-${heatpumpSetPoint.heatpump}`} sx={{ minWidth: cardWidth, minHeight: cardHeight, padding: "10px", backgroundColor: "tertiary.main" }} >
      <Typography color='secondary' variant="h6">
        Wärmepumpe
      </Typography>
      <Checkbox
        checked={heatpumpSetPoint?.sg_ready}
        onChange={(value) => { setHeatpumpSgReady && setHeatpumpSgReady(heatpumpSetPoint, value) }}
        disabled={!setHeatpumpSgReady}
        label={<Typography >
          PV-Einschaltbefehl {infoText && <InfoTooltip>
            Ihre Wärmepumpe ist SG-Ready-fähig.
            Dies bedeutet, dass die Soll-Temperatur erhöht werden kann, wenn viel Sonnenenergie verfügbar ist.
            Zur Schonung Ihrer Wärmepumpe sollte dies jedoch nicht beliebig oft erfolgen.
            Die Steuerung der Wärmepumpe übernimmt das System für Sie basierend auf Wetter- und Lastvorhersagen.
            Daher sehen Sie hier lediglich den Status, können Ihn aber nicht manuell ändern.
            Bitte beachten Sie, dass es ein paar Tage dauern kann, bis die Soll-Temperatur der Wärmepumpe das erste Mal bei hoher PV-Erzeugung erhöht wird, da zunächst ein Kalibiervorgang für die Schätzung der Verbrauchswerte läuft.
          </InfoTooltip>}
        </Typography>}
      />
    </Card>
  )
}

HeatpumpCard.propTypes = {
  heatpumpSetPoint: PropTypes.object,
  setHeatpumpSgReady: PropTypes.func,
  infoText: PropTypes.bool,
  session: PropTypes.object
}
