import React, { useState } from 'react'
import Router from './Router'
import { API_URL_CURRENTUSER, API_URL_LOGOUT, API_URL_LOGIN, API_URL_CREDS } from '../urls'
import backendClient from './backendClient'



export default function AppSession() {

  const login = async (username, password) => {
    return backendClient.post(API_URL_LOGIN, { username, password })
      .then(res => {
        localStorage.setItem('accessToken', res.data.access)
        localStorage.setItem('refreshToken', res.data.refresh)
      })
      .then(() => backendClient.get(API_URL_CREDS)
        .then(res => localStorage.setItem('creds', JSON.stringify(res.data))))
      .then(resetUser)
  }

  const LOGOUT_SESSION = {
    isAuthenticated: false,
    user: null,
    token: null,
    logout: () => { },
    login: login,
    resetUser: () => { },
    backendClient: backendClient
  }

  const logout = async () => {
    return backendClient.post(API_URL_LOGOUT, { refresh: localStorage.getItem('refreshToken') })
      .catch((e) => console.error(e))
      .then(() => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('user')
        localStorage.removeItem('creds')
        resetSession()
      })
  }

  const getSession = () => {
    const user = localStorage.getItem('user')
    const token = localStorage.getItem('accessToken')
    const creds = localStorage.getItem('creds')
    return (!token)
      ? LOGOUT_SESSION
      : {
        isAuthenticated: true,
        user: user && user !== 'undefined' ? JSON.parse(user) : {},
        token,
        login,
        logout,
        resetUser,
        backendClient: backendClient,
        creds: creds && creds !== 'undefined' ? JSON.parse(creds) : {},
      }
  }

  const resetUser = async () => {
    return backendClient.get(API_URL_CURRENTUSER)
      .then(res => localStorage.setItem('user', JSON.stringify(res.data)))
      .then(resetSession)
  }


  const [session, setSession] = useState({ ...getSession() })

  const resetSession = () => {
    setSession(getSession())
  }

  return <Router
    resetUser={resetUser}
    session={session}
  />
}
