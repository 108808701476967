

export const getGrowattAccountFormTemplate = (setForm, locations, usernameDisabled, session) => [
  {
    type: 'input_chars',
    key: 'username',
    name: 'Benutzer',
    width: '50%',
    disabled: usernameDisabled
  },
  {
    type: 'input_chars',
    key: 'password',
    name: 'Passwort',
    width: '50%',
  },
  ...(locations
    ? [{
      type: 'dropdown',
      key: 'location',
      name: 'Standort',
      options: locations.map(l => ({ label: l.name || `${l.street_and_number} ${l.zip_and_city}`, value: l.id }))
    }]
    : [])
]
