import { checkIfValueIsEmpty, isValidEmailAddress } from "../../../react_utils/utils"




export const getCustomerFormTemplate = (setForm, locations, session) => [
  {
    type: 'input_chars',
    key: 'username',
    name: 'Benutzer',
    width: '50%',
    disabled: true
  },
  {
    type: 'input_chars',
    key: 'first_name',
    name: 'Vorname',
    width: '50%',
  },
  {
    type: 'input_chars',
    key: 'last_name',
    name: 'Nachname',
    width: '50%',
  },
  {
    type: 'input_chars',
    key: 'email',
    name: 'E-Mail',
    width: '50%',
    isEmpty: (value) => checkIfValueIsEmpty(value) || !isValidEmailAddress(value)
  },
  ...(locations
    ? [{
      type: 'dropdown',
      key: 'location',
      name: 'Standort',
      options: locations.map(l => ({ label: l.name || `${l.street_and_number} ${l.zip_and_city}`, value: l.id }))
    }]
    : [])
]
