import React from 'react'
import { PropTypes } from 'prop-types'
import { Spinner, Fade } from 'reactstrap'

import { Stack, Typography, Button } from '@mui/material'

import { isString } from '../utils'
import { CustomButton } from '../StyledElements'

export default function SaveModalFooter({ inProgress, submitError, disabled = false, onSave, onCancel, onDelete, saveBtnLabel = 'Speichern', cancelBtnLabel = 'Abbrechen', delteBtnLabel = "Löschen", saveDisabled, id }) {
  return (
    <Stack direction="row" spacing={1}>
      {inProgress ? <Spinner color="light" size="sm" /> : null}
      {submitError
        ? <Fade in={!!submitError} >
          <Typography color={'red'}>
            {isString(submitError) ? submitError : 'Anfrage konnte nicht an Server übermittelt werden!'}
          </Typography>
        </Fade>
        : null}
      {onDelete
        ? (
          <CustomButton
            onClick={onDelete}
            disabled={(inProgress || disabled)}
            color="error"
          >
            {delteBtnLabel}
          </CustomButton>
        )
        : null}
      <CustomButton
        id={id}
        onClick={onSave}
        disabled={(inProgress || disabled || saveDisabled)}
      >
        {saveBtnLabel}{(inProgress) ? '...' : ''}
      </CustomButton>

      {onCancel
        ? (
          <CustomButton
            onClick={onCancel}
            disabled={inProgress}>
            {cancelBtnLabel}
          </CustomButton>
        )
        : null}


    </Stack>)
}

SaveModalFooter.propTypes = {
  inProgress: PropTypes.bool,
  submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  completeBtnLabel: PropTypes.string,
  saveBtnLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  delteBtnLabel: PropTypes.string,
  saveDisabled: PropTypes.bool,
  completeDisabled: PropTypes.bool,
  id: PropTypes.string
}
