import React, { Fragment, useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'

import { Grid } from '@mui/material'


import LoadingPage from '../../../react_utils/LoadingPage'
import { API_URL_WALLBOXCHARGINGHISTORY } from '../../../urls'
import ChargingHistoryTable from '../wallbox/ChargingHistoryTable'

export default function ChargingHistoryTab({ location, session }) {
  const [chargingHistory, setChargingHistory] = useState(null)


  useEffect(() => {
    if (!location) return
    const params = {
      wallbox__location: location.id
    }
    session.backendClient.get(API_URL_WALLBOXCHARGINGHISTORY, { params }).then((res) => {
      setChargingHistory(res.data)
    })

  }, [location])

  if (chargingHistory === null) return <LoadingPage />


  return (
    <Fragment>
      <Grid container justify="center">
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          <ChargingHistoryTable session={session} chargingHistory={chargingHistory} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

ChargingHistoryTab.propTypes = {
  session: PropTypes.object
}
