import React, { useEffect, useRef } from 'react'

import axios from 'axios'
import moment from 'moment'

import { Typography } from '@mui/material'

import { BASE_URL } from '../urls'


export const numFormatter = (data) => {
  return parseFloat(data).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const removeFromObj = (obj, keys) => {
  obj = { ...obj }
  Object.keys(obj)
    .filter(key => keys.includes(key))
    .forEach(key => delete obj[key])
  return obj
}

export const popFromObj = (obj, key) => {
  if (!key in obj) return null
  const val = obj[key]
  delete obj[key]
  return val
}


export const removeFromArr = (arr, condition) => {
  const newArr = []
  let item = null
  arr.forEach(x => {
    if (item === null && condition(x)) item = x
    else newArr.push(x)
  })
  return [item, newArr]
}

export const filterObj = (obj, keys) => {
  const keyCondition = (typeof keys === 'function') ? keys : key => keys.includes(key)
  obj = { ...obj }
  Object.keys(obj)
    .filter(key => !keyCondition(key))
    .forEach(key => delete obj[key])
  return obj
}



export const splitList = (arr, condition) => {
  const objs1 = []
  const objs2 = []
  arr.forEach(item => {
    if (condition(item)) objs1.push(item)
    else objs2.push(item)
  })
  return [objs1, objs2]
}


export const isString = (v) => (typeof v === 'string' || v instanceof String)

export const defaultIfEmpty = value => (value === null || value === undefined) ? '' : value

export const round = (number, decimal = 0) => Math.round(number * 10 ** decimal) / (10 ** decimal)

export const removeDuplicates = (arr) => [...new Set(arr)]

export const arrSum = (arr) => arr.reduce((pv, cv) => pv + cv, 0)

export const arrange = (start, end = null, step = 1) => {
  if (end === null) {
    end = start
    start = 0
  }
  const arr = []
  for (let i = start; i < end; i += step) arr.push(i)
  return arr
}


export const fillString = (string, len) => {
  const strLen = string.length
  const diff = len - strLen
  if (diff <= 0) return string
  return ' '.repeat(diff + 1) + string
}

export const filledNumFormatter = (data, len) => fillString(numFormatter(data), len)

export const date2String = (date) => (date && !isNaN(date.valueOf())) ? moment(date).format('YYYY-MM-DD') : null

export const date2MonthString = (date) => (date && !isNaN(date.valueOf())) ? moment(date).format('YYYY-MM') : null

export const date2YearString = (date) => (date && !isNaN(date.valueOf())) ? moment(date).format('YYYY') : null

export const string2Date = (string) => string ? moment(string, 'YYYY-MM-DD').toDate() : null

export const datetime2String = (date) => (date && !isNaN(date.valueOf())) ? moment(date).format('YYYY-MM-DD HH-mm-ss') : null

export const string2Datetime = (string) => string ? moment(string, 'YYYY-MM-DD HH-mm-ss').toDate() : null

export const date2FormattedString = (date) => date ? moment(date).format('DD.MM.YYYY') : ""

export const datetime2FormattedString = (date) => date ? moment(date).format('DD.MM.YYYY HH:mm') : ""

export const string2FormattedString = (string) => string ? date2FormattedString(string2Date(string)) : null

export const formattedString2Date = (string) => string ? moment(string, 'DD.MM.YYYY').toDate() : null

export const getRandomId = () => Math.floor(Math.random() * (10000000 - 1 + 1) + 1)

export const getImageWindowWithAuth = (image, handleLoad) => {
  const createImageWindow = (url) => {
    const newImageWindow = new window.Image()
    newImageWindow.src = url
    newImageWindow.addEventListener('load', () => handleLoad(newImageWindow))
  }
  if (!isString(image)) {
    createImageWindow(URL.createObjectURL(image))
    return
  } else if (!(image.includes(BASE_URL)) && image.startsWith('/')) {
    image = BASE_URL + image.substring(1)
  }

  const token = localStorage.getItem('accessToken')
  axios.get(image, { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' })
    .then(res => {
      const reader = new window.FileReader()
      reader.readAsDataURL(res.data)
      reader.onload = () => {
        const imageDataUrl = reader.result
        createImageWindow(imageDataUrl)
      }
    })
}

export const splitIntoBatches = (arr, nItems) => {
  if (arr.length === 0) return []
  const batches = [[]]
  arr.forEach(i => {
    const lastBatch = batches[batches.length - 1]
    if (lastBatch.length < nItems) lastBatch.push(i)
    else batches.push([i])
  })
  return batches
}

export function usePreviousValue(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useDidMountEffect(func, deps) {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}

export const isValidEmailAddress = (address) => {
  if (address != null) return !!address.match(/.+@.+\..+/s)
  else return 0
}

export function isValidPhoneNumber(number) {
  if (number !== null) {
    const match = number.match(/(\(?([\d \-)–+/(]+){6,}\)?([ .\-–/]?)([\d]+))/g)
    return match && match[0] === number
  }
  return false
}

export const googleAddress = (streetAndNumber, zipAndCity) => {
  return <a href={'https://maps.google.com/?q=' + streetAndNumber + ' ' + zipAndCity} rel="noreferrer" target="_blank"> {streetAndNumber}; {zipAndCity}</a>
}

export function sortById(arr) {
  // function compareFn(a, b) {
  //     return (a.id == b.id) ? 0 : ((a.id < b.id) ? -1 : 1)
  // }
  return [...arr].sort((a, b) => a.id - b.id)
}

export function generateString(length, onlyCapital = true) {
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  if (!onlyCapital) characters = characters + 'abcdefghijklmnopqrstuvwxyz'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const THIS_YEAR = (new Date()).getFullYear()
export const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
export const SHORT_MONTHS = ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']

export const clone = (x) => JSON.parse(JSON.stringify(x))

const getOptionalKeys = (form, emptyForm) => {
  return Object.keys(form)
    .filter(key => !Object.keys(emptyForm).includes(key))
}

export const checkIfValueIsEmpty = (val) => (val === null || val === '' || (Array.isArray(val) && val.length === 0))

export const getEmptyFields = (form, emptyForm, additionalOptionalKeys = [], checkIfEmpty = null) => {
  const optionalKeys = getOptionalKeys(form, emptyForm)
  optionalKeys.push(...additionalOptionalKeys)
  const keys = Object.keys(form).filter(x => !optionalKeys.includes(x))
  if (checkIfEmpty == null) checkIfEmpty = (key, val, form) => checkIfValueIsEmpty(val)
  const emptyFields = keys.filter(key => checkIfEmpty(key, form[key], form))
  return emptyFields.length !== 0 ? emptyFields : null
}

export const getEmptyFieldsError = (form, emptyForm, additionalOptionalKeys = [], checkIfEmpty = null) => {
  const emptyFields = getEmptyFields(form, emptyForm, [...additionalOptionalKeys, 'id'], checkIfEmpty)
  return emptyFields ? `Error when submitting as following fields are not filled: ${emptyFields}` : null
}


export const getUnderlinedOpenButton = (text) => {
  const getOpenButton = (toggle) =>
    <Typography
      color='primary' style={{ textDecoration: 'underline', fontSize: 14, cursor: 'pointer' }}
      onClick={toggle}>
      {text}
    </Typography>
  return getOpenButton
}

export const zfill = (num, len) => (Array(len).join("0") + num).slice(-len)


export const convertToFormData = (form, fileKeys = [], excludeKeys = []) => {
  const formData = new FormData()
  Object.keys(form)
    .filter(key => !excludeKeys.includes(key))
    .forEach(key => {
      if (fileKeys.includes(key)) {
        if (!isString(form[key])) { // TODO: url
          const file = form[key]
          if (form[key]) formData.append(key, file, file.name) // null not possible
        }
      } else formData.append(key, (form[key] !== null) ? form[key] : '')
    })
  return formData
}

export const germanCalendarMsgs = {
  next: <>&#707;</>,
  previous: <>&#706;</>,
  today: 'Heute',
  month: 'Monat',
  week: 'Woche',
  day: 'Tag',
  work_week: 'Arbeitswoche',

  date: 'Datum',
  time: 'Zeit',
  event: 'Event',
  allDay: 'Ganzer Tag',
  yesterday: 'Gestern',
  tomorrow: 'Morgen',
  agenda: 'Agenda',
  noEventsInRange: 'In diesem Bereich gibt es keine Ereignisse.',

  showMore: total => `+${total} mehr`
}

export const getCalendarRange = (date, view) => {
  let rangeStart, rangeEnd
  switch (view) {
    case 'day':
      rangeStart = moment(date).startOf('day').toDate()
      rangeEnd = moment(date).endOf('day').toDate()
      break
    case 'week':
      rangeStart = moment(date).startOf('isoWeek').toDate()
      rangeEnd = moment(date).endOf('isoWeek').toDate()
      break
    case 'month':
      // add seven days because in the month view you can see the adjacent weeks
      rangeStart = moment(date).startOf('month').subtract(7, 'days').toDate()
      rangeEnd = moment(date).endOf('month').add(7, 'days').toDate()
      break
    case 'work_week':
      rangeStart = moment(date).startOf('isoWeek').toDate()
      rangeEnd = moment(rangeStart).add(5, 'days').toDate()
      break
    default:
      return null
  }
  return { start: rangeStart, end: rangeEnd }
}

export const getNextDay = (n = 1) => {
  const today = new Date() // get today's date
  return addNDays(today, n)
}

export const addNDays = (date, nDays) => {
  const newdate = new Date(date)
  newdate.setDate(date.getDate() + nDays) // add n days (for tomorrow 1)
  return newdate
}

export const addNMinutes = (date, nMinutes) => {
  return moment(date).add(nMinutes, 'm').toDate();
}


export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    : null
}

export const hexTextColor = (hex) => {
  const rgbColor = hexToRgb(hex)
  return ((rgbColor.r * 0.299 + rgbColor.g * 0.587 + rgbColor.b * 0.114) > 186) ? '#000000' : '#ffffff'
}


export const isBoolean = (val) => val === true || val === false


