import axios from "axios";
import { API_URL_LOGOUT, API_URL_REFRESH_TOKEN, BASE_URL } from "../urls";
const backendClient = axios.create({
  // baseURL: 'https://api.GitHub.com/',
  baseURL: BASE_URL
});
backendClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const logout = async () => {
  return axios.post(BASE_URL + API_URL_LOGOUT, { refresh: localStorage.getItem('refreshToken') })
    .catch((e) => console.error('Error in logout', e))
    .then(() => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('user')
    })

}

backendClient.interceptors.response.use(
  (res) => {
    // TODO: if status code 206, make axios request and do request again
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const refreshToken = localStorage.getItem('refreshToken');
    //originalConfig._retry = false
    if (originalConfig?.url !== "api/auth/login" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry && refreshToken) {
        originalConfig._retry = true;
        try {
          const res = await axios.post(BASE_URL + API_URL_REFRESH_TOKEN, { 'refresh': refreshToken }).catch(() => {
            console.log('Refreshing Token failed');
            return logout().then(() => null)
          });
          if (res === null) return

          const { access: accessToken } = res.data;
          localStorage.setItem('accessToken', accessToken)

          return backendClient(originalConfig);
        } catch (_error) {
          console.error('Reject error', _error)
          return Promise.reject(_error);
        }
      } else if (err.response.status === 401) { logout() }
    }

    return Promise.reject(err);
  }
)

export default backendClient