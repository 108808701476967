import React, { Fragment, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

import { Dialog, Grid, IconButton, Typography } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'

import { usePreviousValue, useDidMountEffect, isBoolean } from '../utils'

import './modal.css'

export default function CustomModal ({ title, size, getOpenButton, getFooter, isOpen = null, setIsOpen = null, onToggle, onClose, children }) {
  const [_isOpen, _setIsOpen] = useState((setIsOpen) ? isOpen : false)
  const prevIsOpen = usePreviousValue(_isOpen)

  const toggle = () => {
    if (setIsOpen) setIsOpen(!isOpen)
    else _setIsOpen(!_isOpen)
  }

  useEffect(() => {
    if (setIsOpen && _isOpen !== isOpen) _setIsOpen(isOpen)
  }, [isOpen])

  useDidMountEffect(() => {
    if (isBoolean(prevIsOpen) && isBoolean(_isOpen) && onToggle) onToggle(_isOpen)
  }, [_isOpen])

  const sizeProbs = (size === 'fullscreen') ? { fullScreen: true } : { maxWidth: size, fullWidth: true }// (size === 'sm') ? '300px' : (size === 'lg') ? '800px' : (size === 'xl') ? '1000px' : '500px' }
  const headerProbs = { style: { height: (size === 'fullscreen') ? '30px' : '65px' } }
  const footerProbs = (size === 'fullscreen') ? { style: { height: '40px', padding: '0px' }, className: 'small-modal-footer modal-footer' } : { style: {} }

  return <Fragment>
    {getOpenButton ? getOpenButton(toggle) : null}
    <Dialog
      style={{
        zIndex: 7500 // in front dialog,
      }}
      open={_isOpen}
      onClose={() => { if (onClose) onClose(toggle); else toggle() }}
      {...sizeProbs}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle {...headerProbs} className='modal-header' >
        <Grid container>
          <Grid xs={10} sm={10} md={10} item justifyContent="flex-start">
            <Typography inline='true' fontSize='h5.fontSize' color='primary'>{title}</Typography>
          </Grid>
          <Grid xs={2} sm={2} md={2} item justifyContent="flex-end" display="grid">
            <IconButton
              onClick={() => { if (onClose) onClose(toggle); else toggle() }}
              aria-label="close"
            >
              <CloseIcon color='primary'/>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {(children && _isOpen)
        ? <DialogContent sx={{backgroundColor: 'background.main'}}>
          {children}
        </DialogContent>
        : null}
      {getFooter
        ? <DialogActions className='modal-footer' {...footerProbs}>
          {getFooter(toggle)}
        </DialogActions>
        : null}

    </Dialog>
  </Fragment>
}

CustomModal.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  getOpenButton: PropTypes.func,
  getFooter: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onToggle: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node
}
