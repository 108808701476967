import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { Grid } from '@mui/material';

import { datetime2FormattedString, string2Datetime } from '../../../react_utils/utils';
import ClientConfigureFormModal from '../clients/ClientConfigureFormModal';
import AddClientModal from './AddClientModal';
import Table from '../../../react_utils/table/Table';


export default function LocationClients({ location, resetParent, session }) {

  const [chosenClient, setChosenClient] = useState(null)

  if (!location) return null


  return (
    <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
      {/* <ClientLocationFormModal
        session={session}
        resetParent={resetParent}
        client={chosenClient}
        isOpen={!!chosenClient && !chosenClient.location}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenClient(null) }} /> */}
      <AddClientModal
        location={location}
        resetParent={resetParent}
        session={session}
      />
      <ClientConfigureFormModal
        session={session}
        resetParent={resetParent}
        client={chosenClient}
        isOpen={!!chosenClient}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenClient(null) }} />
      <Table
        columns={[
          { name: 'Geräte-ID', key: 'id' },
          { name: 'Zuletzt online', key: 'timestamp' },
        ]}
        rows={location.impulsclient_set.map(client => ({
          id: client.serial,
          timestamp: datetime2FormattedString(string2Datetime(client.latest_query_timestamp)),
          link: () => setChosenClient(client)
        }))}
      />
    </Grid>
  )
}

LocationClients.propTypes = {
  location: PropTypes.object,
  session: PropTypes.object
}