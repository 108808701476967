export const PROTOCOL = process.env.REACT_APP_PROTOCOL
export const BACKEND_IP = process.env.REACT_APP_BACKEND_IP
export const BASE_URL = PROTOCOL + '://' + BACKEND_IP + '/'

// User / Authentication
export const API_URL_LOGIN = 'api/auth/login'
export const API_URL_LOGOUT = 'api/auth/logout'
export const API_URL_CREDS = 'api/auth/creds'
export const API_URL_CURRENTUSER = 'api/auth/currentuser'
export const API_URL_REFRESH_TOKEN = 'api/auth/token/refresh'
export const API_URL_FORGOT_PASSWORD = 'api/auth/forgotpassword'
export const API_URL_PASSWORDCHANGE = 'api/auth/passwordchange'


// Impuls Clients
export const API_URL_CLIENT = 'api/impulsclient/'
export const API_URL_ASSIGNCLIENT = 'api/impulsclient/assign/'
export const API_URL_CONFIGURECLIENT = 'api/impulsclient/configure/'
export const API_URL_CLIENTSSHTUNNEL = 'api/impulsclient/sshtunnel/'

// Growatt
export const API_URL_GROWATTACCOUNT = 'api/growatt/'

// Devices
export const API_URL_WALLBOX = 'api/devices/wallbox/'
export const API_URL_ROOF = 'api/devices/inverter/roof/'
export const API_URL_WALLBOXSETPOINT = 'api/devices/wallbox/setpoint/'
export const API_URL_WALLBOXCHARGINGHISTORY = 'api/devices/wallbox/charginghistory/'
export const API_URL_HEATPUMPSETPOINT = 'api/devices/heatpump/setpoint/'
export const API_URL_HEATPUMPSGREADYHISTORY = 'api/devices/heatpump/sgreadyhistory/'

// Users
export const API_URL_EMPLOYEE = 'api/user/employee/'
export const API_URL_CUSTOMER = 'api/user/customer/'
export const API_URL_ASSIGNCUSTOMER = 'api/user/customer/assign/' // not used ?

// Location
export const API_URL_LOCATION = 'api/location/'

// Customer urls
export const API_URL_cFLOW = 'api/c/devices/flow/'
export const API_URL_cHOURLY_FLOW = 'api/c/devices/hourlyflow/'
export const API_URL_cDAILY_FLOW = 'api/c/devices/dailyflow/'
export const API_URL_cMONTHLY_FLOW = 'api/c/devices/monthlyflow/'
export const API_URL_cWALLBOX = 'api/c/devices/wallbox/'
export const API_URL_cWALLBOXSETPOINT = 'api/c/devices/wallbox/setpoint/'
export const API_URL_cWALLBOXCHARGINGHISTORY = 'api/c/devices/wallbox/charginghistory/'
export const API_URL_cHEATPUMPSETPOINT = 'api/c/devices/heatpump/setpoint/'

export const API_URL_cHEATPUMPSGREADYHISTORY = 'api/c/devices/heatpump/sgreadyhistory/'
// Demo
export const API_URL_HEATPUMPPROG = 'api/demo/heatpump-prognosis/'
export const API_URL_FLOWHISTORY = 'api/demo/flow-history/'
export const API_URL_WALLBOXCHARGING = 'api/demo/wallbox-charging/'
export const API_URL_ENERGYPRICES = 'api/demo/price-charts/'
export const API_URL_TIBBER = 'api/demo/tibber/'
export const API_URL_AWATTAR = 'api/demo/awattar/'

export const API_URL_FLOW = 'api/devices/flow/'
export const API_URL_HOURLY_FLOW = 'api/devices/hourlyflow/'
export const API_URL_DAILY_FLOW = 'api/devices/dailyflow/'
export const API_URL_MONTHLY_FLOW = 'api/devices/monthlyflow/'
