import React, { Fragment, useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'

import { Grid, Typography } from '@mui/material'

import LoadingPage from '../../react_utils/LoadingPage'
import { API_URL_cHEATPUMPSGREADYHISTORY } from '../../urls'
import InfoTooltip from '../../react_utils/InfoTooltip'
import SgReadyHistoryTable from './heatpump/SgReadyHistoryTable'

export default function SgReadyHistory({ session }) {
  const [sgReadyHistory, setSgReadyHistory] = useState(null)


  useEffect(() => {
    session.backendClient.get(API_URL_cHEATPUMPSGREADYHISTORY).then((res) => {
      setSgReadyHistory(res.data)
    })

  }, [])

  if (sgReadyHistory === null) return <LoadingPage />


  return (
    <Fragment>
      <div className="text-center">
        <Typography sx={{ marginY: '10px' }}>
          SG-Ready-Historie <InfoTooltip>
            Die SG-Ready-Fähigkeit Ihrer Wärmepumpe ermöglicht, dass die Soll-Temperatur Ihrer Wärmepumpe bei viel verfügbarer Sonnenenergie erhöht werden kann,
            um Ihre erzeugte Energie nicht ins Netz einzuspeisen, sondern optimal zu nutzen.
            Zur Schonung Ihrer Wärmepumpe sollte die Erhöhung der Soll-Temperatur jedoch nicht beliebig oft erfolgen.
            Die Steuerung der Wärmepumpe übernimmt das System für Sie basierend auf Wetter- und Lastvorhersagen.
            Bitte beachten Sie, dass es ein paar Tage dauern kann, bis die Soll-Temperatur der Wärmepumpe das erste Mal bei hoher PV-Erzeugung erhöht wird, da zunächst ein Kalibiervorgang für die Schätzung der Verbrauchswerte läuft.
          </InfoTooltip>
        </Typography>
      </div>
      <Grid container justify="center">
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          <SgReadyHistoryTable session={session} sgReadyHistory={sgReadyHistory} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

SgReadyHistory.propTypes = {
  session: PropTypes.object
}
