import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { IconButton } from '@mui/material'

import ConfirmationModalFooter from './ConfirmationModalFooter'
import CustomModal from './CustomModal'

export default function ConfirmationModal({ title, confirm, resetParent, getOpenButton, btnLabel, ...props }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  const onToggle = (isOpen) => {
    if (!isOpen) clearData()
  }

  const clearData = () => {
    setLoadingElements({ submitError: false, inProgress: false })
  }

  const onConfirm = (onSuccess) => {
    setLoadingElements({ ...loadingElements, submitError: false, inProgress: true })
    confirm().then(() => {
      resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false })
    }).catch(() => { setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }


  const getFooter = (toggle) => {
    return (
      <ConfirmationModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onConfirm={() => onConfirm(toggle)}
        onCancel={toggle}
        btnLabel={btnLabel}
        btnColor={"error"}
      />
    )
  }

  return <CustomModal
    getOpenButton={getOpenButton}
    getFooter={getFooter}
    onToggle={onToggle}
    size="md"
    title={title}
    {...props}
  />
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  confirm: PropTypes.func,
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  btnLabel: PropTypes.string
}
