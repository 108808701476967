import React from 'react'

import { PropTypes } from 'prop-types'


import { date2FormattedString } from '../../../react_utils/utils'
import Table from '../../../react_utils/table/Table'

export default function ChargingHistoryTable({ chargingHistory, session }) {


  return (
    <Table
      columns={[
        { name: 'Datum', key: 'date' },
        { name: 'Lademenge [kWh]', key: 'kW_charged' },
        { name: 'Dauer [Std]', key: 'duration' },
        { name: 'Start', key: 'start' },
        { name: 'Ende', key: 'end' }
      ]}
      rows={chargingHistory.map(info => ({
        date: date2FormattedString(info.start_time.slice(0, 10)),
        kW_charged: info.energy ? info.energy.toFixed(2) : '0',
        // pv_energy: (info.self_supply * 100).toFixed(0),
        duration: info.duration,
        start: info.start_time.slice(11, 16),
        end: info.end_time ? info.end_time.slice(11, 16) : '',
      }))}
    />
  )
}

ChargingHistoryTable.propTypes = {
  chargingHistory: PropTypes.arrayOf(PropTypes.object),
  session: PropTypes.object
}
