import React, { Fragment, useState } from 'react';

import { PropTypes } from 'prop-types';

import { useDidMountEffect } from '../../../react_utils/utils';
import CharsInput from '../../../react_utils/fields/CharsInput';
import CustomModal from '../../../react_utils/modals/CustomModal';
import SaveModalFooter from '../../../react_utils/modals/SaveModalFooter';
import CustomSnackbar from '../../../react_utils/CustomSnackbar';


export default function WallboxNameModal({ wallbox, editWallboxName, resetParent, isOpen, setIsOpen, getOpenButton, session }) {

  const [newWallboxName, setNewWallboxName] = useState(wallbox?.name || '')

  // messaging
  const [snackbar, setSnackbar] = useState({ open: false, msg: '', severity: 'success' })


  const onToggle = (isOpen) => {
    if (isOpen) setNewWallboxName(wallbox.name)
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        disabled = {!newWallboxName}
        onSave={() => onSubmit(toggle)}
        saveBtnLabel={"Speichern"}
      />)
  }

  const onSubmit = async (onSuccess) => {
    return submit(newWallboxName).then((res) => {
      resetParent()
      onSuccess()
      setSnackbar(s => ({ ...s, msg: "Name geändert.", open: true }))
    }).catch(error => { console.error('Error in "WallboxNameModal:onSubmit"', error, error.stack);})
  }

  const submit = async (newWallboxName) => {
    return editWallboxName(wallbox, newWallboxName)
  }

  return (
    <Fragment>
      <CustomModal size='sm' title={"Name ändern"} getFooter={getFooter} isOpen={isOpen} setIsOpen={setIsOpen} getOpenButton={getOpenButton} onToggle={onToggle}>
      <CharsInput
        label="Name"
        onChange={v => setNewWallboxName(v)}
        value={newWallboxName}
      />
      </CustomModal>
      <CustomSnackbar severity={snackbar.severity} message={snackbar.msg} duration={3000} open={snackbar.open} setIsOpen={isOpen => setSnackbar(s => ({ ...s, open: isOpen }))} />
    </Fragment>
  )
}

WallboxNameModal.propTypes = {
  editWallboxName: PropTypes.func,
  session: PropTypes.object
}