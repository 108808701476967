import colorLib from '@kurkle/color';
// import {DateTime} from 'luxon';
import 'chartjs-adapter-luxon';
import { valueOrDefault } from 'chart.js/helpers';

// // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
// var _seed = Date.now();

// export function srand(seed) {
//   _seed = seed;
// }

// export function rand(min, max) {
//   min = valueOrDefault(min, 0);
//   max = valueOrDefault(max, 0);
//   _seed = (_seed * 9301 + 49297) % 233280;
//   return min + (_seed / 233280) * (max - min);
// }

// export function numbers(config) {
//   var cfg = config || {};
//   var min = valueOrDefault(cfg.min, 0);
//   var max = valueOrDefault(cfg.max, 100);
//   var from = valueOrDefault(cfg.from, []);
//   var count = valueOrDefault(cfg.count, 8);
//   var decimals = valueOrDefault(cfg.decimals, 8);
//   var continuity = valueOrDefault(cfg.continuity, 1);
//   var dfactor = Math.pow(10, decimals) || 0;
//   var data = [];
//   var i, value;

//   for (i = 0; i < count; ++i) {
//     value = (from[i] || 0) + this.rand(min, max);
//     if (this.rand() <= continuity) {
//       data.push(Math.round(dfactor * value) / dfactor);
//     } else {
//       data.push(null);
//     }
//   }

//   return data;
// }

// export function points(config) {
//   const xs = this.numbers(config);
//   const ys = this.numbers(config);
//   return xs.map((x, i) => ({ x, y: ys[i] }));
// }

// export function bubbles(config) {
//   return this.points(config).map(pt => {
//     pt.r = this.rand(config.rmin, config.rmax);
//     return pt;
//   });
// }

// export function labels(config) {
//   var cfg = config || {};
//   var min = cfg.min || 0;
//   var max = cfg.max || 100;
//   var count = cfg.count || 8;
//   var step = (max - min) / count;
//   var decimals = cfg.decimals || 8;
//   var dfactor = Math.pow(10, decimals) || 0;
//   var prefix = cfg.prefix || '';
//   var values = [];
//   var i;

//   for (i = min; i < max; i += step) {
//     values.push(prefix + Math.round(dfactor * i) / dfactor);
//   }

//   return values;
// }

// const MONTHS = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December'
// ];

// export function months(config) {
//   var cfg = config || {};
//   var count = cfg.count || 12;
//   var section = cfg.section;
//   var values = [];
//   var i, value;

//   for (i = 0; i < count; ++i) {
//     value = MONTHS[Math.ceil(i) % 12];
//     values.push(value.substring(0, section));
//   }

//   return values;
// }

// const COLORS = [
//   '#4dc9f6', // blue (light)
//   '#f67019', // orange
//   '#f53794', // pink
//   '#537bc4', // purple (light)
//   '#acc236', // green (light)
//   '#166a8f', // blue
//   '#00a950', // green
//   '#58595b', // gray
//   '#8549ba' // purple
// ];

// export function color(index) {
//   return COLORS[index % COLORS.length];
// }

// export function transparentize(value, opacity) {
//   var alpha = opacity === undefined ? 0.5 : 1 - opacity;
//   return colorLib(value).alpha(alpha).rgbString();
// }

const RGB_CHART_COLORS = {
  red: { r: 255, g: 99, b: 132 },
  orange: { r: 255, g: 159, b: 64 },
  yellow: { r: 255, g: 205, b: 86 },
  cyan: { r: 75, g: 192, b: 192 },
  blue: { r: 54, g: 162, b: 235 },
  purple: { r: 153, g: 102, b: 255 },
  green: { r: 74, g: 115, b: 55 },
  grey: { r: 201, g: 203, b: 207 },
  darkgreen: { r: 36, g: 64, b: 37 },
  lightgreen: { r: 144, g: 158, b: 62}
};


export const getColor = (key, opacity = null) => {
  const c = RGB_CHART_COLORS[key]
  if (opacity !== null) {
    return `rgba(${c.r}, ${c.g}, ${c.b}, ${opacity})`
  }
  return `rgb(${c.r}, ${c.g}, ${c.b})`
}

export const getColorByIndex = (idx, opacity = null) => {
  const keys = Object.keys(RGB_CHART_COLORS)
  const key = keys[idx % keys.length];
  return getColor(key, opacity)
}


// export const CHART_COLORS = {
//   red: 'rgb(255, 99, 132)',
//   orange: 'rgb(255, 159, 64)',
//   yellow: 'rgb(255, 205, 86)',
//   green: 'rgb(75, 192, 192)',
//   blue: 'rgb(54, 162, 235)',
//   purple: 'rgb(153, 102, 255)',
//   grey: 'rgb(201, 203, 207)'
// };

// const NAMED_COLORS = Object.values(CHART_COLORS);

// export function namedColor(index) {
//   return NAMED_COLORS[index % NAMED_COLORS.length];
// }

// export function newDate(days) {
//   return DateTime.now().plus({days}).toJSDate();
// }

// export function newDateString(days) {
//   return DateTime.now().plus({days}).toISO();
// }

// export function parseISODate(str) {
//   return DateTime.fromISO(str);
// }