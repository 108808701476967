import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { Grid } from '@mui/material';

import CustomerFormModal from '../users/CustomerFormModal';
import Table from '../../../react_utils/table/Table';


export default function LocationUsers({ location, resetParent, session }) {

  const [chosenUser, setChosenUser] = useState(null)

  if (!location) return null


  return (
    <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
      {/* <Typography variant="h6" align="center">Nutzer</Typography> */}
      <CustomerFormModal
        location={location}
        resetParent={resetParent}
        session={session}
      />
      <CustomerFormModal
        customer={chosenUser}
        isOpen={!!chosenUser}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenUser(null) }}
        getOpenButton={null}
        location={location}
        resetParent={resetParent}
        session={session}
      />
      <Table
        columns={[
          { name: 'Vorname', key: 'first_name' },
          { name: 'Nachname', key: 'last_name' },
          { name: 'E-Mail', key: 'email' },
          { name: 'Nutzer', key: 'username' }
        ]}
        rows={location.user_set.map(user => ({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          username: user.username,
          link: () => setChosenUser(user)
        }))}
      />
    </Grid>
  )
}

LocationUsers.propTypes = {
  location: PropTypes.object,
  session: PropTypes.object
}