import React, { Fragment, useState } from 'react'

import { PropTypes } from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import { Col, Container, Row } from 'reactstrap'

import { IconButton, Typography, Collapse, Grid, Card, CardContent, CardMedia } from '@mui/material'

import Icon from '../../react_utils/Icon'
import LogoHeader from '../../react_utils/LogoHeader'
import { splitIntoBatches } from '../../react_utils/utils'

export default function Home({ navigation, session }) {
  const history = useHistory()
  const iconfontsize = isMobileOnly ? 30 : 40


  const [openCollapse, setOpenCollapse] = useState(null)

  const route = (item) => {
    history.push(`/${item.url}/`)
  }


  const getParentItem = (item, level) => {
    return <Row>
      <span onClick={() => setOpenCollapse(item.key)} onMouseOver={() => setOpenCollapse(item.key)} onMouseLeave={() => setOpenCollapse(null)}>
        <div style={{ marginLeft: 40 * level }}>
          <IconButton disabled disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }}>
            <Icon icon={item.icon} color='primary' style={{ fontSize: iconfontsize }} fontSize='large' />
            &nbsp;
            <Typography color='primary' fontSize='h6.fontSize' sx={{ fontWeight: 'bold' }}>{item.name}</Typography>
          </IconButton>
        </div>
        {item.subpages
          ? <Collapse in={openCollapse === item.key} timeout="auto" unmountOnExit>
            {item.subpages.map(c => getListItem(c, level + 1))}
          </Collapse>
          : null}
      </span>
    </Row>
  }

  const getChildItem = (item, level) => {
    return <div style={{ marginLeft: 40 * level }}>
      <Row>
        <Link to={`/${item.url}/`} style={{ textDecoration: 'none' }}>
          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }}>
            <Icon icon={item.icon} color='primary' style={{ fontSize: iconfontsize }} fontSize='large' />
            &nbsp;
            <Typography color='primary' fontSize='h6.fontSize'>{item.name}</Typography>
          </IconButton>
        </Link>
      </Row>
    </div>
  }

  const getListItem = (item, level) => {
    return item.url ? getChildItem(item, level) : getParentItem(item, level)
  }

  const onCardClick = (item) => {
    if (item.url) route(item)
    else setOpenCollapse(item.key)
  }

  if (isMobileOnly) return (
    <Grid paddingTop='20px' container spacing={3} >
      {navigation
        .map((item, idx) =>
          <Grid key={`nav-item-${idx}`} item xs={12} sm={6} md={2}>
            <Card onClick={() => onCardClick(item)} sx={{ padding: '5px', backgroundColor: 'tertiary.main' }}>
            {/* style={{ padding: '5px', boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)' }} */}
              <CardMedia style={{ display: 'flex', justifyContent: 'center' }}>
                <Icon icon={item.icon} sx={{ fontSize: 40, color: 'secondary.main'}} />
                &nbsp;
                <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="h5">
                  {item.name}
                </Typography>
              </CardMedia>
              <Collapse ali in={openCollapse === item.key} timeout="auto" unmountOnExit >
                <CardContent >
                  {item.subpages ? item.subpages.map(c => getListItem(c, 0)) : null}
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
        )}
    </Grid>
  )

  return (
    <Container>
      {session.user ? <Typography paddingTop={'10px'} color='primary' className={'text-end'}>Angemeldet: {session.user.username} </Typography> : null}
      <div className="text-center">
        <Typography fontSize='h4.fontSize' color='primary'>
          Home
        </Typography>
      </div>
      <br />
      <Row>
        <Col>
          <LogoHeader />
        </Col>
        {splitIntoBatches(navigation, 5)
          .map((batch, bIdx) =>
            <Col key={`home-overview-batch-${bIdx}`}>
              {batch.map((item, iIdx) =>
                <Fragment key={`batch-${bIdx}-item-${iIdx}`}>
                  {getListItem(item, 0)}
                  <br />
                </Fragment>
              )}
            </Col>
          )}
      </Row>
    </Container>
  )
}

Home.propTypes = {
  session: PropTypes.object
}
