import React, { Fragment, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Stack } from '@mui/material';
import { isString } from '../utils';
// # cursor: 'default',

function TypographyWrapper({ children, ...props }) {
  if (!isString(children)) return children
  if (!children) children = " " // if the typography is empty the link is not created
  return <Typography color='secondary' {...(props || {})}>{children}</Typography>
}

function LinkWrapper({ link, children }) {
  if (!link) return children
  return isString(link) ? <Link to={link} style={{ textDecoration: 'none', width: '100%' }}>{children}</Link> : <div onClick={link}>{children}</div>
}

const cellStyle = { padding: 8 }
const rowStyle = { '& > *': { borderBottom: 'unset' } }

function Cell({row, col, collapsible, rowOpen, setRowOpen}){
  const [active, setActive] = useState(false);
  const link = col.getLink ? col.getLink(row) : row.link
  return <TableCell
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={(!link && collapsible) ? () => setRowOpen(!rowOpen) : null}
      style={{ ...cellStyle, ...{ cursor: (collapsible || link) ? 'pointer' : 'default' } }} {...(col.rowprops || {})}>
    <LinkWrapper link={link}>
    <Stack direction="row" spacing={2}>
      <TypographyWrapper>{row[col.key] || ""}</TypographyWrapper>
      {active && col.onActive && col.onActive(row)}
      </Stack>
    </LinkWrapper>
  </TableCell>
}

function Row({ row, columns, collapsible }) {
  const [open, setOpen] = useState(false)
  return (
    <Fragment>
      <TableRow sx={rowStyle}>
        {collapsible
          ? <TableCell style={cellStyle} onClick={() => setOpen(!open)} >
            {open ? <KeyboardArrowUpIcon color='secondary' /> : <KeyboardArrowDownIcon color='secondary' />}
          </TableCell>
          : null}
        {columns.map((col, cIdx) => <Cell  row={row} col={col} collapsible={collapsible} key={`table-row-col${cIdx}`} rowOpen={open} setRowOpen={setOpen}/> )}
      </TableRow>
      {
        collapsible
          ? <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 2}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  {row.collapse || <></>}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          : null
      }
    </Fragment >
  );
}

Row.propTypes = {
};


export default function Table({ columns, rows, collapsible = false, emptyTableText = null }) {
  // columns: { name, key, props={} }
  //    headerprops (e.g. align="right/left")
  //    rowprops
  return (
    <TableContainer >
      <MuiTable aria-label="collapsible table" sx={{ backgroundColor: 'transparent' }}>
        <TableHead>
          <TableRow>
            {collapsible ? <TableCell /> : null}
            {columns.map((col, cIdx) => <TableCell style={{ ...cellStyle, ...(col.headerprops?.style || {}) }} key={`table-header-col${cIdx}`} {...(col.headerprops || {})}><TypographyWrapper fontWeight="bold">{col.name || ""}</TypographyWrapper></TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {(!rows || rows.length === 0) && emptyTableText &&
            <TableRow sx={rowStyle}>
              <TableCell colSpan={columns.length + collapsible} style={cellStyle}>
                <TypographyWrapper align="center">{emptyTableText}</TypographyWrapper>
              </TableCell>
            </TableRow>}
          {rows && rows.map((row, rIdx) => <Row key={`table-row${rIdx}`} columns={columns} row={row} collapsible={collapsible} />)}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  collapsible: PropTypes.bool,
  // pagination: PropTypes.object
};