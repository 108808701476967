import React, { Fragment } from 'react'
import { isMobileOnly } from 'react-device-detect'


import { PropTypes } from 'prop-types'

import { ThemeProvider as MuiThemeProvider, createTheme, CssBaseline } from '@mui/material'

import MiniDrawer from './MiniDrawer'

const theme = createTheme({
  palette: {
    primary: {
      main: "#efefef", // white
      contrastText: '#000000', // black
    },
    secondary: {
      main: "#34a894", // turquoise
      contrastText: '#efefef', // white
    },
    tertiary: {
      main: "#323436", // gray
      contrastText: '#efefef',
    },
    disabled: {
      main: "#adadad", // gray
    },
    error: {
      //main: '#7a2b2b',
      main: "#d32f2f"
    },
    // success: {
    //   main: '#1f6638'
    // },
    background: {
      default: "#232323", // dark gray
      main: "#232323",
      contrastText: '#ffffff'
    },
    text: {
      primary: '#ffffff', // white.
      secondary: '#ffffff', // white
      disabled: '#adadad' // light gray
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: "#323436",
        },
        option: {
          backgroundColor: "#323436",
        }

      },
    },
  },
  // components: {
  //   MuiAutocomplete: {
  //     styleOverrides: {
  //       option: {
  //         '&[aria-selected="true"]': {
  //           backgroundColor: 'blue',
  //         },

  //         '&:hover': {
  //           backgroundColor: 'green',
  //         },
  //         backgroundColor: 'red',
  //       },
  //     },
  //   },
  // },
})

export default function Layout({ session, pageName, navigation = null, children }) {

  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {(navigation != null)
          ? <>
            <MiniDrawer session={session} pageName={pageName} navigation={navigation} />
            <div style={{ paddingLeft: isMobileOnly ? '10px' : '100px', paddingRight: isMobileOnly ? '10px' : '100px' }}>
              {children}
            </div>
          </>
          : children
        }
      </MuiThemeProvider>
    </Fragment>
  )
}

Layout.propTypes = {
  session: PropTypes.object,
  pageName: PropTypes.string,
  children: PropTypes.node
}
