import { useEffect, useState } from 'react';
import ReactFlow, { Controls, Background } from 'reactflow';



export default function Flow({ flowheight, nodes, edges, edgeTypes, nodeTypes }) {
  const [flowInstance, setFlowInstance] = useState(null);

  const onLoad = (rf) => {
    setFlowInstance(rf);
  };

  useEffect(() => {
    if (flowInstance) {
      flowInstance.fitView();
    }
  }, [flowInstance, nodes, edges]);


  return (
    // <div style={{ height: '100%' }}>
    <div style={{ height: flowheight, width: 800 }}>
      <ReactFlow
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        panOnDrag={false}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        onInit={onLoad}
        edgesUpdatable={false}
        edgesFocusable={false}
        nodesDraggable={false}
        nodesConnectable={false}
        nodesFocusable={false}
        draggable={false}
        elementsSelectable={false}
        preventScrolling={false}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}
