import React from 'react'

import { PropTypes } from 'prop-types'

import { date2FormattedString } from '../../../react_utils/utils'
import Table from '../../../react_utils/table/Table'

export default function SgReadyHistoryTable({ sgReadyHistory, session }) {

  return (
    <Table
      columns={[
        { name: 'Datum', key: 'date' },
        { name: 'Dauer [Std]', key: 'duration' },
        { name: 'Start', key: 'start' },
        { name: 'Ende', key: 'end' }
      ]}
      rows={sgReadyHistory.map(info => ({
        date: date2FormattedString(info.start_time.slice(0, 10)),
        duration: info.duration,
        start: info.start_time.slice(11, 16),
        end: info.end_time ? info.end_time.slice(11, 16) : '',
      }))}
    />
  )
}

SgReadyHistoryTable.propTypes = {
  sgReadyHistory: PropTypes.arrayOf(PropTypes.object),
  session: PropTypes.object
}
