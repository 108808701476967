
import { getBezierPath, BaseEdge, EdgeLabelRenderer } from 'reactflow';

export default function FlowEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  label,
  markerEnd,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const color = data?.color || '#34a894'
  const fontcolor = data?.fontcolor || '#efefef'
  const showCircle = data?.value !== 0
  const showLabel = data?.value !== 0
  const dx = (targetX - sourceX)
  const dy = (targetY - sourceY)
  const xp = (Math.abs(dx / dy) < 5) ? 10 : -50
  const yp = (Math.abs(dy / dx) < 5) ? 0 : -50

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={{ stroke: `${color}`, strokeWidth: 4 }} />
      {showLabel ?
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(${xp}%, ${yp}%) translate(${labelX}px,${labelY}px)`,
              fontSize: 10,
              backgroundColor: 'transparent',
              borderRadius: 20,
              pointerEvents: 'all',
              padding: '4px',
              zIndex: 100,
              color: (fontcolor)
            }}
            className="nodrag nopan"
          >
            {label} kW
          </div>
        </EdgeLabelRenderer>
        : null}
      {showCircle ?
        <circle
          style={{ filter: `drop-shadow(0px 0px 1px ${color}` }}
          r="4"
          fill={`${color}`}
          className="circle"
        >
          <animateMotion dur="2s" repeatCount="indefinite" path={edgePath} begin="0s" />
        </circle>
        : null}
    </>
  );
}